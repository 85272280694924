import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Container } from './style'
import { Column, H1, Input, Row, Title } from 'styles/global'
import { Skeletons, JobOfferCard, Filter } from 'components'
import JobOfferList from 'components/jobOfferList'

import { jobOfferService } from 'services'
import { useQuery } from 'react-query'
import { JobOffer } from 'types'
import { FilterParams } from 'components/GlobalComponents/filter'
import InfiniteScroll from 'react-infinite-scroll-component'
import { routes } from 'router'
import { urlParams } from 'utils'

const JobOffers = () => {
  const [displayedJobOffers, setDisplayedJobOffers] = useState<JobOffer[]>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [isEndOfList, setIsEndOfList] = useState<boolean>(false)
  const [totalItems, setTotalItems] = useState<number>(0)
  const location = useLocation()
  const paramsUrl = urlParams()
  const [filterParams, setFilterParams] = useState<FilterParams>(
    paramsUrl || {
      country: '',
      region: '',
      department: '',
      contractType: '',
      jobType: '',
    },
  )

  const localisationInformation = filterParams.city
    ? filterParams.city
    : filterParams.department
    ? filterParams.department
    : filterParams.region
    ? filterParams.region
    : ''

  const navigate = useNavigate()

  const setUrlQuery = () => {
    const query = `?department=${filterParams.department || ''}&city=${
      filterParams.city || ''
    }&country=${filterParams.country || ''}&region=${filterParams.region || ''}&contractType=${
      filterParams.contractType || ''
    }`
    navigate(`${routes.viewOffers.path}${query}`)
    return true
  }

  const {
    isLoading,
    data: jobOffers,
    isSuccess: isDatasLoad,
  } = useQuery(['jobOffers', JSON.stringify(filterParams), currentPage], () =>
    jobOfferService.find({ ...filterParams, page: currentPage }).then((res) => {
      setUrlQuery()
      setTotalItems(res.totalItems || 0)
      if (currentPage === 1) {
        setDisplayedJobOffers(res.data)
      } else {
        setDisplayedJobOffers([...displayedJobOffers, ...res.data])
      }
      if (!res.data?.length) {
        setIsEndOfList(true)
      }
      return [...displayedJobOffers, ...res.data]
    }),
  )

  return (
    <Container>
      <Helmet>
        <title>
          {localisationInformation
            ? `Liste des offres d'emploi Dentiste ${localisationInformation}`
            : `Les meilleures offres d'emploi Dentiste ${localisationInformation}`}{' '}
        </title>
        <meta
          name="description"
          content="Retrouver les meilleures offres d'emploi Pharamcien du marché. Trier et filtrer par régions, salaires et type de contrat (CDI/CDD) les annonces puis postuler en quelques cliques aux annonces de pharamcien qui vous intéressent"
        />
        <meta name="keywords" content="offres, emploi, pharamcien" />
      </Helmet>
      <Row
        style={{ marginTop: '65px' }}
        responsive="width: 95vw"
        padding="20px 30px"
        width="70vw"
        jusitfyContent="flex-start"
      >
        {(displayedJobOffers.length || isDatasLoad) && (
          <Filter
            filterParams={filterParams}
            setFilterParams={(newParams) => {
              setCurrentPage(1)
              setFilterParams(newParams)
            }}
          />
        )}
      </Row>

      <Column width="60vw" margin="0 0 0 calc(5vw + 100px)" responsive="margin: 0; width: 95vw;">
        <H1
          size="15px"
          style={{ maxWidth: '90vw', marginBottom: '0', width: '70vw' }}
          color="greyBlack"
          weight="400"
        >
          Liste des offres d&apos;emploi Dentiste {localisationInformation} : ({totalItems})
        </H1>
        <JobOfferList
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isLoading={isLoading}
          jobOffers={displayedJobOffers}
          isEndOfList={isEndOfList}
        />
      </Column>
    </Container>
  )
}

export default JobOffers
