import { mediaQuery, styled, theme } from 'styles'
import { Button } from 'styles/global'

export const Container = styled.div`
  display: flex;
  position: relative;
  max-width: 90vw;
  ${mediaQuery.mobile} {
    flex-direction: column;
  }
`

export const ButtonInInput = styled(Button.Primary)`
  border-radius: 0 6px 6px 0;
  width: auto;
  font-size: 14px;
  white-space: nowrap;
  padding: 10px 15px;
  margin-left: -4px;
  box-shadow: 0 0 3px ${theme.color.grey};
  ${mediaQuery.mobile} {
    border-radius: 6px;
    margin-left: 0;
    width: 100%;
    margin-top: 10px;
  }
`
