import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { message } from 'antd'

import { Title, Row, Column, Button, Text, H1, H2, H3, H4 } from 'styles/global'
import { routes } from 'router'
import { InformationsForm } from 'components/candidateAccount'
import { Loader, CandidateTable } from 'components'
import { useAuth } from 'hooks'
import { UserContext } from 'providers'
import { useQuery } from 'react-query'
import { adminServices } from 'services'
import { Candidate, Company } from 'types'
import { CompanyTable } from 'components/adminDashboard'

const AdminDashboard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [displayedCompanies, setDisplayedCompanies] = useState<Company[]>()
  const [displayedCandidates, setDisplayedCandidates] = useState<Candidate[]>()
  const { user } = useContext(UserContext)
  const { logout } = useAuth()

  const navigate = useNavigate()

  const { isLoading: isLoadingCompanies, data: companies } = useQuery(
    ['admin-find-companies'],
    async () => adminServices.findCompanies(),
    {
      onSuccess: (res) => setDisplayedCompanies(res.data),
    },
  )

  const { isLoading: isLoadingCandidates, data: candidates } = useQuery(
    ['admin-find-candidates'],
    async () => adminServices.findCandidates(),
    {
      onSuccess: (res) => setDisplayedCandidates(res.data),
    },
  )

  const handleUpdateCompany = ({ index, newCompany }) => {
    if (displayedCompanies) {
      const newCompanies = [...displayedCompanies]
      newCompanies[index] = newCompany
      setDisplayedCompanies([...newCompanies])

      adminServices
        .updateUser(newCompany, { id: newCompany._id })
        .then((res) =>
          res.status === 200
            ? message.success('correctement mis à jour')
            : message.error('un problème est surevenu'),
        )
    }
  }

  const handleUpdateCandidate = ({ index, newCandidate }) => {
    if (displayedCandidates) {
      const newCandidates = [...displayedCandidates]
      newCandidates[index] = newCandidate
      setDisplayedCandidates([...newCandidates])

      adminServices
        .updateUser(newCandidate, { id: newCandidate._id })
        .then((res) =>
          res.status === 200
            ? message.success('correctement mis à jour')
            : message.error('un problème est surevenu'),
        )
    }
  }

  return (
    <Column width="100%" alignItems="center">
      <Loader isDisplayed={isLoading} />
      <Title margin="20px 0 0 0" weight="bold" color="secondary">
        Espace admin
      </Title>
      <CandidateTable
        candidates={displayedCandidates}
        handleUpdateCandidate={handleUpdateCandidate}
        isLoading={isLoadingCandidates}
      />
      <CompanyTable
        companies={displayedCompanies}
        handleUpdateCompany={handleUpdateCompany}
        isLoading={isLoadingCompanies}
      />
      <Button.Primary onClick={logout}>Se déconnecter</Button.Primary>
    </Column>
  )
}

export default AdminDashboard
