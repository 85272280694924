import React from 'react'
import { Link } from 'react-router-dom'
import { theme } from 'styles'
import { H2, Text } from 'styles/global'

import { PartColumn } from '../style'
import { routes } from 'router'

const Part19 = () => {
  return (
    <PartColumn style={{ marginBottom: '-80px' }} blue id="title-18">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Votre partenaire pour le recrutement de dentiste
      </H2>
      <Text margin="15px 0">
        Quel que soit votre besoin en <strong>recrutement de dentiste</strong>, que vous soyez un{' '}
        <strong>cabinet dentaire en pleine croissance</strong> ou une{' '}
        <strong>entreprise du secteur dentaire en quête de nouvelles compétences</strong>,
        emploi-dentiste est l'allié incontournable qui vous aidera à atteindre vos objectifs. En
        tant que plateforme spécialisée, nous mettons un point d’honneur à vous accompagner
        efficacement dans votre démarche.
        <br />
        <br /> Notre force réside dans notre audience ciblée, constituée de plusieurs centaines de
        dentistes et d'assistantes dentaires. Ainsi, soyez certain que votre offre d’emploi dentiste
        ne se perd pas dans une multitude d'annonces non pertinentes, mais atteint directement des
        candidats qualifiés, actifs et en recherche d'opportunités dans le secteur dentaire. Cette
        spécificité garantit un niveau d'intérêt élevé pour chaque annonce postée sur notre
        plateforme, augmentant ainsi vos chances de trouver le candidat idéal.
        <br />
        <br /> L'heure est venue d'aller plus loin dans votre <strong>recherche de dentiste</strong>
        , de donner une nouvelle impulsion à votre équipe et de renforcer votre activité. Ne laissez
        pas passer cette opportunité. Faites le choix de l'efficacité, de la pertinence et de
        l'expertise.
        <br />
        <br /> Contactez-nous dès aujourd'hui pour avancer dans votre processus de recrutement. Nos
        experts sont prêts à vous accompagner, à répondre à vos questions et à mettre en place les
        solutions qui répondront à vos attentes. Avec emploi-dentiste, votre recrutement prend une
        nouvelle dimension. Nous avons hâte de collaborer avec vous.
        <br />
        <br /> Vous êtes un professionnel à la recherche d’un emploi ?{' '}
        <Link
          style={{ color: theme.color.black, textDecoration: 'underline' }}
          to={routes.candidatHome.path}
        >
          Découvrez notre page spécialement dédiée à la recherche d’emploi de dentiste
        </Link>
        .
        <br />
        <br /> Pour plus d’informations concernant le recrutement dans le secteur dentaire, la
        rédaction d’offres d’emploi optimisées ou les tendances du marché de l’emploi, n’hésitez pas
        à{' '}
        <a
          style={{ color: theme.color.black, textDecoration: 'underline' }}
          rel="noreferrer"
          target="_blank"
          href="https://blog.emploi-dentiste.fr"
        >
          consulter notre Blog spécialisé
        </a>
        .
      </Text>
    </PartColumn>
  )
}

export default Part19
