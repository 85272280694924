/* eslint-disable */

import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { tagList, articles } from '../../pages/blog/articles'

import { urlParams } from 'utils'

import './style.css'

const TagMenu = ({ setDisplayedArticles, selectedTag, setSelectedTag }) => {
  const navigate = useNavigate()

  const paramsUrl = urlParams()

  const handleFilter = (tag, isClicked) => {
    if (isClicked) {
      navigate(tag ? `/blog?tag=${tag}` : '/blog')
    }
    if (setDisplayedArticles) {
      setDisplayedArticles(
        articles.filter(
          (article) =>
            article.tags.some((articleTag) => articleTag?.toLocaleLowerCase() === tag?.toLocaleLowerCase()) || !tag,
        ),
      )
      setSelectedTag(tag)
    }
  }

  useEffect(() => {
    handleFilter(paramsUrl.tag?.replaceAll('%20', ' '))
  }, [])

  return (
    <div className="blog-tag-menu">
      <p
        style={{
          margin: '5px 20px 5px 0',
          fontWeight: !selectedTag ? 'bold' : '',
          cursor: 'pointer',
        }}
        onClick={() => handleFilter('', true)}
      >
        Tous
      </p>
      {tagList.map((tag, index) => {
        return (
          <p
            style={{
              margin: '5px 20px 5px 0',
              fontWeight: selectedTag === tag.toUpperCase() ? 'bold' : '',
              cursor: 'pointer',
            }}
            onClick={() => handleFilter(tag.toUpperCase(), true)}
          >
            {tag}
          </p>
        )
      })}
    </div>
  )
}
export default TagMenu
