import React from 'react'
import { Button, Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/candidat/img14.svg'
import { Img3, PartColumn } from '../style'

const Part26 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-25">
      <Row wrap width="100%" justifyContent="space-between">
        <Img3 style={{ marginBottom: '20px' }} src={Img} alt="feedback utilisateurs" />
        <Column responsive="width: 100%;" width="65%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Feedback des utilisateurs
          </H3>
          <Text margin="15px 0">
            Nous encourageons également les utilisateurs d’emploi-dentiste à nous faire part de
            leurs commentaires sur les offres publiées. Si un utilisateur signale un problème avec
            l’une ou l’autre de nos offres, nous enquêtons et prenons les mesures nécessaires pour
            résoudre le problème. Nous sommes déterminés à vous fournir des offres d'emploi de
            qualité pour vous aider dans votre recherche professionnelle.
          </Text>
        </Column>
      </Row>
      <Row margin="25px 0 0 0" justifyContent="center" width="100%">
        <Button.Primary
          width="350px"
          minHeight="45px"
          onClick={(e) => handleClickButton(e, 'default')}
        >
          Rejoindre emploi-dentiste
        </Button.Primary>
      </Row>
    </PartColumn>
  )
}

export default Part26
