import { styled, theme, mediaQuery } from 'styles'
import { Column, Card as CardG } from 'styles/global'

export const Container = styled(Column)`
  width: 100vw;
  min-height: 100vh;
  align-items: center;
`

export const HeadContainer = styled(Column)`
  background: ${theme.color.secondary};
  width: 100vw;
  padding: 50px 12vw 200px 12vw;
  align-items: center;
`

export const Img = styled.img`
  height: 120px;
  width: auto;
`

export const Card = styled(CardG)`
  width: 300px;
  min-height: 400px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
