import React, { Dispatch, SetStateAction } from 'react'

import { Select } from 'styles/global'
import { countrys } from 'datas/countrys'

const { Option } = Select

type Props = {
  setSelectedCountry: any
  isMultiple?: boolean
  style?: any
  defaultValue?: any
}

const CountrysSelect = ({ setSelectedCountry, style, isMultiple, defaultValue }: Props) => {
  return (
    <Select
      defaultValue={defaultValue || undefined}
      mode={isMultiple && 'tags'}
      allowClear
      showSearch
      width="150px"
      responsive="width: 100%;"
      style={style}
      placeholder="Pays"
      optionFilterProp="children"
      onChange={setSelectedCountry}
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      <Option value={'*'}>Tous les pays</Option>
      {countrys.map((country, index) => (
        <Option key={index} value={country.name}>
          {country.name}
        </Option>
      ))}
    </Select>
  )
}

export default CountrysSelect
