import React from 'react'
import { Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/company/img5.svg'
import { Img3, PartColumn } from '../style'

const Part5 = () => {
  return (
    <PartColumn id="title-4">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Annonce visible par tous les professionnels du secteur dentaire
          </H3>
          <Text margin="15px 0">
            Lorsque vous postez votre offre d’emploi dentiste sur notre site, votre annonce est
            instantanément visible par tous les professionnels qui sont activement à la recherche
            d'opportunités dans le secteur dentaire. Notre objectif est d’assurer la réelle
            visibilité de votre offre aux candidats potentiellement intéressés par votre cabinet.
          </Text>
        </Column>
        <Img3 src={Img} alt="annonce emploi" />
      </Row>
    </PartColumn>
  )
}

export default Part5
