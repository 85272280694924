import { JobOfferApplicationsServices } from 'types/api'
import { PATHS, ROUTE, getHeader, response } from 'api'

const jobOfferApplicationsServices: JobOfferApplicationsServices = {
  newApplication: async (body, { jobOfferId }) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER_APPLICATIONS.DEFAULT)}/${jobOfferId}`, {
      method: 'POST',
      body: JSON.stringify({ ...body }),
      headers,
    })
    return await response({ promise, serviceType: '', service: '' })
  },
  findOne: async ({ jobOfferId }) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER_APPLICATIONS.DEFAULT)}/${jobOfferId}`, {
      method: 'GET',
      headers,
    })
    return await response({ promise, serviceType: '', service: '' })
  },
  findByCandidate: async () => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER_APPLICATIONS.DEFAULT)}/my-applications`, {
      method: 'GET',
      headers,
    })
    return await response({ promise, serviceType: '', service: '' })
  },
}

export default jobOfferApplicationsServices
