import React, { useState, useEffect, Dispatch, SetStateAction } from 'react'
import { message, Modal } from 'antd'
import { useTranslation } from 'react-i18next'

import { urlParams } from 'utils'
import { Title, Text, ColumnCenter, Column, Row, Input, Button, InputPassword } from 'styles/global'
import { ColumnResetPasswordRules, Rules } from 'components/login/style'
import { PASSWORD_REGEX, EMAIL_REGEX } from 'constants/index'
import { authService } from 'services'
import { routes } from 'router'
import Password from 'antd/lib/input/Password'

type BottomButtonsProps = {
  validateCallback: () => void
  setIsDisplayed: Dispatch<SetStateAction<boolean>>
  t: (string) => string
}

const BottomButtons = ({ validateCallback, setIsDisplayed, t }: BottomButtonsProps) => {
  return (
    <Row responsive={`flex-direction: column-reverse;`}>
      <Button.Secondary onClick={() => setIsDisplayed(false)} margin="0 10px">
        {t('cancel')}
      </Button.Secondary>
      <Button.Primary
        responsive={`margin-bottom: 10px;`}
        onClick={validateCallback}
        margin="0 10px"
      >
        {t('send')}
      </Button.Primary>
    </Row>
  )
}

type Props = {
  isDisplayed: boolean
  setIsDisplayed: Dispatch<SetStateAction<boolean>>
}

const ResetPassword = ({ isDisplayed, setIsDisplayed }: Props) => {
  const [currentStep, setCurrentStep] = useState({
    SEND_EMAIL: true,
    RESET_PASSWORD: false,
  })
  const [email, setEmail] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>('')
  const paramsUrl = urlParams()
  const { t } = useTranslation()

  useEffect(() => {
    if (paramsUrl.resetToken) {
      setCurrentStep({
        SEND_EMAIL: false,
        RESET_PASSWORD: true,
      })
    }
  }, [])

  // check if we are on a reset password url
  useEffect(() => {
    if (paramsUrl.resetCode) {
      setIsDisplayed(true)
      setCurrentStep({
        SEND_EMAIL: false,
        RESET_PASSWORD: true,
      })
    }
  }, [])

  const sendResetEmail = () => {
    if (EMAIL_REGEX.test(email)) {
      authService
        .reset({
          email: email,
        })
        .then((res) => {
          message[res.status === 200 ? 'success' : 'error'](t(res.message))
          if (res.status === 200) {
            setIsDisplayed(false)
          }
        })
    } else {
      message.error(t('badEmail'))
    }
  }

  const validResetPassword = () => {
    if (!(newPassword.length >= 8)) {
      message.error('Votre mot de passe doit faire au minimum 8 caractères')
    } else if (newPassword !== newPasswordRepeat) {
      message.error('Le mot de passe répété de correspond pas')
    } else {
      authService
        .validReset({
          email: decodeURI(paramsUrl.email),
          password: newPassword,
          userId: paramsUrl.userId,
          resetCode: paramsUrl.resetCode,
        })
        .then((res) => {
          message[res.status === 200 ? 'success' : 'error'](t(res.message))
          if (res.status === 200) {
            setIsDisplayed(false)
          }
        })
    }
  }

  const handleChangePassword = (event) => {
    setNewPassword(event.target.value)
  }

  return (
    <Modal visible={isDisplayed} footer={[]} onCancel={() => setIsDisplayed(false)}>
      {currentStep.SEND_EMAIL ? (
        <ColumnCenter>
          <Title weight="bold">{t('auth.resetPassword.step1.title')}</Title>
          <Text margin="15px 0 0 0" size="18px" position="center">
            {t('auth.resetPassword.step1.subTitle')}
          </Text>

          <Column margin="50px 0 35px 0">
            <Text margin="0 0 5px 0">{t('auth.resetPassword.step1.placeholder')}</Text>
            <Input
              onPressEnter={sendResetEmail}
              width="300px"
              placeholder={t('auth.resetPassword.step1.placeholder')}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Column>
          <BottomButtons validateCallback={sendResetEmail} setIsDisplayed={setIsDisplayed} t={t} />
        </ColumnCenter>
      ) : (
        <ColumnCenter>
          <Title weight="bold">{t('auth.resetPassword.step2.title')}</Title>
          <Row margin="50px 0 35px 0" responsive={`flex-direction: column; align-items: center`}>
            <Column margin="20px">
              <Text margin="0 0 5px 0">{t('auth.resetPassword.step2.placeholder')}</Text>
              <InputPassword
                width="300px"
                placeholder={t('auth.resetPassword.step2.placeholder')}
                onChange={handleChangePassword}
              />
              <Text margin="20px 0 5px 0">{t('auth.resetPassword.step2.placeholder2')}</Text>
              <InputPassword
                width="300px"
                placeholder={t('auth.resetPassword.step2.placeholder2')}
                onChange={(event) => setNewPasswordRepeat(event.target.value)}
              />
            </Column>
          </Row>
          <BottomButtons
            validateCallback={validResetPassword}
            setIsDisplayed={setIsDisplayed}
            t={t}
          />
        </ColumnCenter>
      )}
    </Modal>
  )
}

export default ResetPassword
