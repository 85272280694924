import React, { Dispatch, SetStateAction } from 'react'

import { Select } from 'styles/global'
import { departments } from 'datas/departments'

const { Option } = Select

type Props = {
  setSelectedDepartments: any
  isMultiple?: boolean
  style?: any
  defaultValue?: any
}

const DepartmentsSelect = ({ setSelectedDepartments, style, isMultiple, defaultValue }: Props) => {
  return (
    <Select
      defaultValue={defaultValue || undefined}
      mode={isMultiple && 'tags'}
      allowClear
      showSearch
      width="150px"
      responsive="width: 100%;"
      style={style}
      placeholder="Départements"
      optionFilterProp="children"
      onChange={(value) => setSelectedDepartments(value)}
      filterOption={(input, option) =>
        (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
      }
    >
      <Option value={'*'}>Tous les départements</Option>
      {departments.map((department, index) => (
        <Option key={department.code} value={department.code}>
          {`(${department.code}) ${department.name}`}
        </Option>
      ))}
    </Select>
  )
}

export default DepartmentsSelect
