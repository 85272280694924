import React from 'react'
import { H3, Text } from 'styles/global'

import { PartColumn } from '../style'

const Part18 = () => {
  return (
    <PartColumn id="title-17">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Un processus simple et efficace
      </H3>
      <Text margin="15px 0">
        Nous savons que le processus de recrutement peut être exigeant et stressant. C'est pourquoi
        nous faisons tout notre possible pour le rendre aussi simple et efficace que possible. Avec
        emploi-dentiste, vous avez la certitude de bénéficier d'une plateforme fiable, efficace et
        dédiée à vos besoins. Vous pouvez donc vous concentrer sur ce qui compte vraiment : trouver
        les meilleurs professionnels pour votre établissement.
        <br /> Alors n'attendez plus. Faites le choix de la fiabilité, de l'efficacité et de
        l'expertise pour votre <strong>recrutement de dentiste ou d’assistante dentaire</strong>.
      </Text>
    </PartColumn>
  )
}

export default Part18
