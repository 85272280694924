import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/company/img7.svg'
import { Img2, PartColumn } from '../style'
import { routes } from 'router'

const Part9 = ({   handleClickButton, }: {   handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-8">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Une base de CV enrichie
          </H3>
          <Text margin="15px 0">
            En choisissant emploi-dentiste, vous bénéficiez d'un accès illimité à notre base de{' '}
            <strong>CV de professionnels du secteur dentaire</strong>. Cette base de données, mise à jour
            quotidiennement, représente un véritable trésor d'informations, un outil qui vous permet
            de découvrir des profils diversifiés et qualifiés de candidats et de sélectionner ceux
            qui correspondent le mieux à vos critères et à vos attentes. <br />
            <br /> Expérience, compétences, formations ou disponibilités, chaque information est
            soigneusement renseignée et facilement accessible, ce qui vous permet d'optimiser votre
            temps et d'orienter votre choix vers les candidats les plus pertinents.
          </Text>
        </Column>
        <Column responsive="width: 100%;" width="30%">
          <Img2
            style={{ width: '100%', marginBottom: '20px' }}
            src={Img}
            alt="cvThèque"
          />
          <Button.Third width="100%" onClick={(e) => handleClickButton(e, routes.candidateList.path)}>
            Découvrir notre cvThèque
          </Button.Third>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part9
