import React, { Dispatch, SetStateAction } from 'react'
import { H2, Row, Text, Button } from 'styles/global'

import { PartColumn } from '../style'
import { routes } from 'router'

const Part11 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-10">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Un Accompagnement personnalisé dans votre recherche dentiste
      </H2>
      <Text margin="15px 0">
        Chez emploi-dentiste, nous sommes conscients que chaque <strong>cabinet dentaire</strong> et{' '}
        <strong>chaque entreprise du secteur dentaire</strong> possèdent des besoins spécifiques et
        uniques. Nous savons que ce qui fonctionne pour un établissement peut ne pas fonctionner
        pour un autre. C'est pourquoi nous avons adopté une approche personnalisée pour accompagner
        nos partenaires dans leur recrutement.
      </Text>
      <Row margin="25px 0 0 0" justifyContent="center" width="100%">
        <Button.Primary
          width="350px"
          minHeight="45px"
          onClick={(e) => handleClickButton(e, routes.contact.path)}
        >
          Nous contacter pour en savoir plus
        </Button.Primary>
      </Row>
    </PartColumn>
  )
}

export default Part11
