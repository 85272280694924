import React from 'react'
import { H3, Text } from 'styles/global'

import { PartColumn } from '../style'

const Part21 = () => {
  return (
    <PartColumn id="title-20">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Utiliser les filtres de recherche
      </H3>
      <Text margin="15px 0">
        Emploi-dentiste dispose de filtres de recherche avancés qui vous permettent de trier les
        offres d'emploi par région, type de contrat, niveau d'expérience requis, etc. Utilisez ces
        filtres pour affiner votre recherche et trouver plus rapidement les offres qui correspondent
        à vos critères.
      </Text>
    </PartColumn>
  )
}

export default Part21
