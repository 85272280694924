/* eslint-disable */

import React, { useState } from 'react'

import Login from 'components/login'
import { Button } from 'styles/global'

import { Input } from 'antd'
import './style.css'

const GetStarted = () => {
  const [email, setEmail] = useState('')
  const [isRegisterDisplayed, setIsRegisterDisplayed] = useState('')

  return (
    <div className="get-started-container">
      <Login
        isDisplayed={isRegisterDisplayed}
        setIsDisplayed={setIsRegisterDisplayed}
        isRegistration={true}
        defaultEmail={email}
      />
      <Input
        onPressEnter={() => {
          setIsRegisterDisplayed('/')
        }}
        placeholder={'Votre adresse email'}
        onChange={(e) => {
          setEmail(e.target.value)
        }}
        className="get-started-input"
      />
      <Button.Primary
        onClick={() => setIsRegisterDisplayed('/')}
        style={{
          boxShadow: '0px 2px 5px rgb(200, 200, 200)',
          right: '-5px',
          fontSize: '16px',
          width: '120px',
          position: 'absolute',
          height: '40px',
        }}
      >
        Commencer
      </Button.Primary>
    </div>
  )
}

export default GetStarted
