import React, { Dispatch, SetStateAction } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Title } from 'styles/global'
import { Skeletons, JobOfferCard } from 'components'

import { JobOffer } from 'types'

type Props = {
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  isEndOfList: boolean
  jobOffers: JobOffer[]
  isLoading: boolean
}

const JobOfferList = ({
  currentPage,
  setCurrentPage,
  isEndOfList,
  jobOffers,
  isLoading,
}: Props) => {
  return isLoading && !jobOffers.length ? (
    <Skeletons />
  ) : jobOffers.length ? (
    <InfiniteScroll
      dataLength={jobOffers.length} //This is important field to render the next data
      next={() => !isLoading && setCurrentPage(currentPage + 1)}
      refreshFunction={() => !isLoading && setCurrentPage(currentPage + 1)}
      hasMore={!isEndOfList}
      loader={<Skeletons />}
      pullDownToRefresh
      pullDownToRefreshThreshold={50}
    >
      {jobOffers?.map((jobOffer, index) => (
        <JobOfferCard key={index} keyId={index} jobOffer={jobOffer} />
      ))}
    </InfiniteScroll>
  ) : (
    <Title margin="30px 0 0 0">Aucun résultats</Title>
  )
}

export default JobOfferList
