import React, { Dispatch, SetStateAction } from 'react'
import { Modal } from 'antd'
import { Column, Text } from 'styles/global'

type Props = {
  email: string
  phone: string
  companyName: string
  isDisplayed: boolean
  setIsDisplayed: Dispatch<SetStateAction<boolean>>
}

const ViewContact = ({ email, phone, companyName, isDisplayed, setIsDisplayed }: Props) => {
  return (
    <Modal visible={isDisplayed} onCancel={() => setIsDisplayed(false)} footer={[]}>
      <Column width="100%" padding="50px">
        <Text size="16px" margin="0 0 30px 0">
          Coordonnées de <span style={{ fontWeight: 'bold' }}>{companyName}</span>
        </Text>
        {email && (
          <Text>
            <span style={{ fontWeight: 'bold' }}>Adresse email : </span> {email}
          </Text>
        )}
        {phone && (
          <Text margin="10px 0 0 0">
            <span style={{ fontWeight: 'bold' }}>Téléphone : </span> {phone}
          </Text>
        )}
      </Column>
    </Modal>
  )
}

export default ViewContact
