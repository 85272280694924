import React from 'react'
import { Column, H2, Row, Text } from 'styles/global'

import Img from 'assets/landings/company/img10.svg'
import { Img3, PartColumn } from '../style'

const Part17 = () => {
  return (
    <PartColumn id="title-16">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="60%">
          <H2 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Comment optimiser votre recherche d'emploi dentiste sur notre plateforme ?
          </H2>
          <Text margin="15px 0">
            Pour maximiser vos chances de trouver un <strong>emploi dentiste</strong> ou un{' '}
            <strong>emploi assistante dentaire</strong> qui correspond à vos aspirations et à vos
            compétences, il est essentiel d'optimiser votre recherche. Voici quelques conseils pour
            vous aider à tirer le meilleur parti d’emploi-dentiste.
          </Text>
        </Column>
        <Img3 src={Img} alt="optimiser recherche emploi" />
      </Row>
    </PartColumn>
  )
}

export default Part17
