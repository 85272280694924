import { styled, theme, mediaQuery } from 'styles'
import { Card, Column, Text } from 'styles/global'

export const Container = styled(Column)`
  background: white;
  width: 100vw;
  padding: 0 10vw;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;
`

export const JobOfferCard = styled(Card)`
  width: 350px;
  height: 400px;
  box-shadow: 0px 0px 2px 0px #cecece;
  cursor: pointer;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: hidden;
  &:hover {
    transform: scale(1.03);
    box-shadow: 0px 0px 3px 0px #cecece;
  }
`

export const Element = styled.p`
  background: ${theme.color.grey};
  border-radius: 6px;
  padding: 5px 15px;
  display: flex;
  margin: 10px 10px 10px 0;
  display: flex;
  align-items: center;
  jusitfy-content: flex-start;
  width: fit-content;
`

export const Icon = styled.img`
  width: 25px;
  height: auto;
  margin-right: 7px;
`

export const DescribeContent = styled(Text)`
  max-width: 270px;
  max-height: 200px;
  overflow: hidden;
`
