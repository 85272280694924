import React from 'react'
import { Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/candidat/img10.svg'
import { Img3, PartColumn } from '../style'

const Part14 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-13">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="65%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Dépôt de votre CV en ligne
          </H3>
          <Text margin="15px 0">
            Une fois que vous avez trouvé une ou plusieurs offres qui vous intéressent, la prochaine
            étape consiste à{' '}
            <Text underline pointer onClick={(e) => handleClickButton(e, 'default')}>
              déposer votre CV en ligne
            </Text>
            . Nous avons rendu ce processus aussi simple que possible. Vous pouvez télécharger votre
            CV existant ou utiliser notre outil de création de CV en ligne pour créer un document
            professionnel visant à mettre en valeur vos compétences et votre expérience. De plus,
            vous pouvez mettre à jour votre CV à tout moment pour refléter vos nouvelles compétences
            ou expériences.
          </Text>
        </Column>
        <Img3 style={{ marginBottom: '20px' }} src={Img} alt="dépot de CV" />
      </Row>
    </PartColumn>
  )
}

export default Part14
