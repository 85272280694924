import React from 'react'
import { Button, H3, Text } from 'styles/global'

import { PartColumn } from '../style'

const Part10 = () => {
  return (
    <PartColumn width="100%" id="title-9">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Les offres d'emploi sont régulièrement mises à jour
      </H3>
      <Text margin="15px 0">
        Nous assurons la mise à jour régulière de nos offres d'emploi pour garantir leur pertinence
        et leur sérieux. Nous collaborons activement avec des cabinets dentaires, des cliniques et
        diverses entités de l'industrie dentaire dans toute la France pour vous présenter des
        opportunités d'emploi à jour. En outre, chaque offre est rigoureusement vérifiée par notre
        équipe avant sa publication, afin de confirmer sa légitimité et son sérieux.
      </Text>
    </PartColumn>
  )
}

export default Part10
