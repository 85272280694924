export const departments = [
  { id: 1, region_code: '84', code: '01', name: 'Ain', slug: 'ain' },
  { id: 2, region_code: '32', code: '02', name: 'Aisne', slug: 'aisne' },
  { id: 3, region_code: '84', code: '03', name: 'Allier', slug: 'allier' },
  {
    id: 4,
    region_code: '93',
    code: '04',
    name: 'Alpes-de-Haute-Provence',
    slug: 'alpes de haute provence',
  },
  { id: 5, region_code: '93', code: '05', name: 'Hautes-Alpes', slug: 'hautes alpes' },
  { id: 6, region_code: '93', code: '06', name: 'Alpes-Maritimes', slug: 'alpes maritimes' },
  { id: 7, region_code: '84', code: '07', name: 'Ard\u00e8che', slug: 'ardeche' },
  { id: 8, region_code: '44', code: '08', name: 'Ardennes', slug: 'ardennes' },
  { id: 9, region_code: '76', code: '09', name: 'Ari\u00e8ge', slug: 'ariege' },
  { id: 10, region_code: '44', code: '10', name: 'Aube', slug: 'aube' },
  { id: 11, region_code: '76', code: '11', name: 'Aude', slug: 'aude' },
  { id: 12, region_code: '76', code: '12', name: 'Aveyron', slug: 'aveyron' },
  {
    id: 13,
    region_code: '93',
    code: '13',
    name: 'Bouches-du-Rh\u00f4ne',
    slug: 'bouches du rhone',
  },
  { id: 14, region_code: '28', code: '14', name: 'Calvados', slug: 'calvados' },
  { id: 15, region_code: '84', code: '15', name: 'Cantal', slug: 'cantal' },
  { id: 16, region_code: '75', code: '16', name: 'Charente', slug: 'charente' },
  { id: 17, region_code: '75', code: '17', name: 'Charente-Maritime', slug: 'charente maritime' },
  { id: 18, region_code: '24', code: '18', name: 'Cher', slug: 'cher' },
  { id: 19, region_code: '75', code: '19', name: 'Corr\u00e8ze', slug: 'correze' },
  { id: 20, region_code: '27', code: '21', name: "C\u00f4te-d'Or", slug: 'cote dor' },
  { id: 21, region_code: '53', code: '22', name: "C\u00f4tes-d'Armor", slug: 'cotes darmor' },
  { id: 22, region_code: '75', code: '23', name: 'Creuse', slug: 'creuse' },
  { id: 23, region_code: '75', code: '24', name: 'Dordogne', slug: 'dordogne' },
  { id: 24, region_code: '27', code: '25', name: 'Doubs', slug: 'doubs' },
  { id: 25, region_code: '84', code: '26', name: 'Dr\u00f4me', slug: 'drome' },
  { id: 26, region_code: '28', code: '27', name: 'Eure', slug: 'eure' },
  { id: 27, region_code: '24', code: '28', name: 'Eure-et-Loir', slug: 'eure et loir' },
  { id: 28, region_code: '53', code: '29', name: 'Finist\u00e8re', slug: 'finistere' },
  { id: 29, region_code: '94', code: '2A', name: 'Corse-du-Sud', slug: 'corse du sud' },
  { id: 30, region_code: '94', code: '2B', name: 'Haute-Corse', slug: 'haute corse' },
  { id: 31, region_code: '76', code: '30', name: 'Gard', slug: 'gard' },
  { id: 32, region_code: '76', code: '31', name: 'Haute-Garonne', slug: 'haute garonne' },
  { id: 33, region_code: '76', code: '32', name: 'Gers', slug: 'gers' },
  { id: 34, region_code: '75', code: '33', name: 'Gironde', slug: 'gironde' },
  { id: 35, region_code: '76', code: '34', name: 'H\u00e9rault', slug: 'herault' },
  { id: 36, region_code: '53', code: '35', name: 'Ille-et-Vilaine', slug: 'ille et vilaine' },
  { id: 37, region_code: '24', code: '36', name: 'Indre', slug: 'indre' },
  { id: 38, region_code: '24', code: '37', name: 'Indre-et-Loire', slug: 'indre et loire' },
  { id: 39, region_code: '84', code: '38', name: 'Is\u00e8re', slug: 'isere' },
  { id: 40, region_code: '27', code: '39', name: 'Jura', slug: 'jura' },
  { id: 41, region_code: '75', code: '40', name: 'Landes', slug: 'landes' },
  { id: 42, region_code: '24', code: '41', name: 'Loir-et-Cher', slug: 'loir et cher' },
  { id: 43, region_code: '84', code: '42', name: 'Loire', slug: 'loire' },
  { id: 44, region_code: '84', code: '43', name: 'Haute-Loire', slug: 'haute loire' },
  { id: 45, region_code: '52', code: '44', name: 'Loire-Atlantique', slug: 'loire atlantique' },
  { id: 46, region_code: '24', code: '45', name: 'Loiret', slug: 'loiret' },
  { id: 47, region_code: '76', code: '46', name: 'Lot', slug: 'lot' },
  { id: 48, region_code: '75', code: '47', name: 'Lot-et-Garonne', slug: 'lot et garonne' },
  { id: 49, region_code: '76', code: '48', name: 'Loz\u00e8re', slug: 'lozere' },
  { id: 50, region_code: '52', code: '49', name: 'Maine-et-Loire', slug: 'maine et loire' },
  { id: 51, region_code: '28', code: '50', name: 'Manche', slug: 'manche' },
  { id: 52, region_code: '44', code: '51', name: 'Marne', slug: 'marne' },
  { id: 53, region_code: '44', code: '52', name: 'Haute-Marne', slug: 'haute marne' },
  { id: 54, region_code: '52', code: '53', name: 'Mayenne', slug: 'mayenne' },
  { id: 55, region_code: '44', code: '54', name: 'Meurthe-et-Moselle', slug: 'meurthe et moselle' },
  { id: 56, region_code: '44', code: '55', name: 'Meuse', slug: 'meuse' },
  { id: 57, region_code: '53', code: '56', name: 'Morbihan', slug: 'morbihan' },
  { id: 58, region_code: '44', code: '57', name: 'Moselle', slug: 'moselle' },
  { id: 59, region_code: '27', code: '58', name: 'Ni\u00e8vre', slug: 'nievre' },
  { id: 60, region_code: '32', code: '59', name: 'Nord', slug: 'nord' },
  { id: 61, region_code: '32', code: '60', name: 'Oise', slug: 'oise' },
  { id: 62, region_code: '28', code: '61', name: 'Orne', slug: 'orne' },
  { id: 63, region_code: '32', code: '62', name: 'Pas-de-Calais', slug: 'pas de calais' },
  { id: 64, region_code: '84', code: '63', name: 'Puy-de-D\u00f4me', slug: 'puy de dome' },
  {
    id: 65,
    region_code: '75',
    code: '64',
    name: 'Pyr\u00e9n\u00e9es-Atlantiques',
    slug: 'pyrenees atlantiques',
  },
  {
    id: 66,
    region_code: '76',
    code: '65',
    name: 'Hautes-Pyr\u00e9n\u00e9es',
    slug: 'hautes pyrenees',
  },
  {
    id: 67,
    region_code: '76',
    code: '66',
    name: 'Pyr\u00e9n\u00e9es-Orientales',
    slug: 'pyrenees orientales',
  },
  { id: 68, region_code: '44', code: '67', name: 'Bas-Rhin', slug: 'bas rhin' },
  { id: 69, region_code: '44', code: '68', name: 'Haut-Rhin', slug: 'haut rhin' },
  { id: 70, region_code: '84', code: '69', name: 'Rh\u00f4ne', slug: 'rhone' },
  { id: 71, region_code: '27', code: '70', name: 'Haute-Sa\u00f4ne', slug: 'haute saone' },
  { id: 72, region_code: '27', code: '71', name: 'Sa\u00f4ne-et-Loire', slug: 'saone et loire' },
  { id: 73, region_code: '52', code: '72', name: 'Sarthe', slug: 'sarthe' },
  { id: 74, region_code: '84', code: '73', name: 'Savoie', slug: 'savoie' },
  { id: 75, region_code: '84', code: '74', name: 'Haute-Savoie', slug: 'haute savoie' },
  { id: 76, region_code: '11', code: '75', name: 'Paris', slug: 'paris' },
  { id: 77, region_code: '28', code: '76', name: 'Seine-Maritime', slug: 'seine maritime' },
  { id: 78, region_code: '11', code: '77', name: 'Seine-et-Marne', slug: 'seine et marne' },
  { id: 79, region_code: '11', code: '78', name: 'Yvelines', slug: 'yvelines' },
  { id: 80, region_code: '75', code: '79', name: 'Deux-S\u00e8vres', slug: 'deux sevres' },
  { id: 81, region_code: '32', code: '80', name: 'Somme', slug: 'somme' },
  { id: 82, region_code: '76', code: '81', name: 'Tarn', slug: 'tarn' },
  { id: 83, region_code: '76', code: '82', name: 'Tarn-et-Garonne', slug: 'tarn et garonne' },
  { id: 84, region_code: '93', code: '83', name: 'Var', slug: 'var' },
  { id: 85, region_code: '93', code: '84', name: 'Vaucluse', slug: 'vaucluse' },
  { id: 86, region_code: '52', code: '85', name: 'Vend\u00e9e', slug: 'vendee' },
  { id: 87, region_code: '75', code: '86', name: 'Vienne', slug: 'vienne' },
  { id: 88, region_code: '75', code: '87', name: 'Haute-Vienne', slug: 'haute vienne' },
  { id: 89, region_code: '44', code: '88', name: 'Vosges', slug: 'vosges' },
  { id: 90, region_code: '27', code: '89', name: 'Yonne', slug: 'yonne' },
  {
    id: 91,
    region_code: '27',
    code: '90',
    name: 'Territoire de Belfort',
    slug: 'territoire de belfort',
  },
  { id: 92, region_code: '11', code: '91', name: 'Essonne', slug: 'essonne' },
  { id: 93, region_code: '11', code: '92', name: 'Hauts-de-Seine', slug: 'hauts de seine' },
  { id: 94, region_code: '11', code: '93', name: 'Seine-Saint-Denis', slug: 'seine saint denis' },
  { id: 95, region_code: '11', code: '94', name: 'Val-de-Marne', slug: 'val de marne' },
  { id: 96, region_code: '11', code: '95', name: "Val-d'Oise", slug: 'val doise' },
  { id: 97, region_code: '01', code: '971', name: 'Guadeloupe', slug: 'guadeloupe' },
  { id: 98, region_code: '02', code: '972', name: 'Martinique', slug: 'martinique' },
  { id: 99, region_code: '03', code: '973', name: 'Guyane', slug: 'guyane' },
  { id: 100, region_code: '04', code: '974', name: 'La R\u00e9union', slug: 'la reunion' },
  { id: 101, region_code: '06', code: '976', name: 'Mayotte', slug: 'mayotte' },
  {
    id: 102,
    region_code: 'COM',
    code: '975',
    name: 'Saint-Pierre-et-Miquelon',
    slug: 'saint pierre et miquelon',
  },
  {
    id: 103,
    region_code: 'COM',
    code: '977',
    name: 'Saint-Barth\u00e9lemy',
    slug: 'saint barthelemy',
  },
  { id: 104, region_code: 'COM', code: '978', name: 'Saint-Martin', slug: 'saint martin' },
  {
    id: 105,
    region_code: 'COM',
    code: '984',
    name: 'Terres australes et antarctiques fran\u00e7aises',
    slug: 'terres australes et antarctiques francaises',
  },
  { id: 106, region_code: 'COM', code: '986', name: 'Wallis et Futuna', slug: 'wallis et futuna' },
  {
    id: 107,
    region_code: 'COM',
    code: '987',
    name: 'Polyn\u00e9sie fran\u00e7aise',
    slug: 'polynesie francaise',
  },
  {
    id: 108,
    region_code: 'COM',
    code: '988',
    name: 'Nouvelle-Cal\u00e9donie',
    slug: 'nouvelle caledonie',
  },
  {
    id: 109,
    region_code: 'COM',
    code: '989',
    name: '\u00cele de Clipperton',
    slug: 'ile de clipperton',
  },
]
