/* eslint-disable */

import React from 'react'
import { Divider } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { routes } from 'router'
import { Row } from 'styles/global'

const ArticleHeaderNav = ({ article }) => {
  return (
    <div style={{ height: '30px', marginTop: '30px' }} className="width-100">
      <Row alignItems="center" width="100%">
        <Link
          style={{ color: '#B9B9B9', fontSize: '13px' }}
          className="text-300 underline pointer"
          to={routes.home.path}
        >
          Home
        </Link>
        <CaretRightOutlined className="grey" style={{ marginLeft: '10px', marginRight: '10px' }} />
        <Link
          style={{ color: '#B9B9B9', fontSize: '13px' }}
          className="text-300 underline pointer"
          to={routes.blogHome.path}
        >
          Blog
        </Link>
        <CaretRightOutlined className="grey" style={{ marginLeft: '10px', marginRight: '10px' }} />
        <p style={{ minHeight: 'auto', marginBottom: '0' }} className="text-bold grey text-ellipsis">
          {article.title}
        </p>
      </Row>
      <Divider style={{ marginTop: '10px' }} />
    </div>
  )
}

export default ArticleHeaderNav
