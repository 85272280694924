import React from 'react'
import { Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/candidat/img11.svg'
import { Img3, PartColumn } from '../style'

const Part23 = () => {
  return (
    <PartColumn style={{ paddingBottom: '0' }} blue id="title-22">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="65%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Vérification des offres d'emploi
          </H3>
          <Text margin="15px 0">
            Avant de publier une offre d'emploi, nous la vérifions soigneusement. Nous nous assurons
            que l'offre provient d'un employeur légitime et que les informations fournies sont
            précises et complètes. Nous vérifions également que l'offre est sérieuse et qu'elle
            correspond à un véritable besoin de recrutement.
            <br /> Voici comment nous assurons la qualité des offres d'emploi sur notre plateforme.
          </Text>
        </Column>
        <Img3 style={{ marginBottom: '20px' }} src={Img} alt="offre emploi vérifiées" />
      </Row>
    </PartColumn>
  )
}

export default Part23
