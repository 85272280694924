import React, { Dispatch, SetStateAction } from 'react'
import { H3, Row, Text, Button } from 'styles/global'

import { PartColumn } from '../style'
import { routes } from 'router'

const Part11 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-10">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Vous pouvez déposer votre CV en ligne
      </H3>
      <Text margin="15px 0">
        Emploi-dentiste offre l'opportunité de soumettre votre CV sur la plateforme en ligne,
        facilitant ainsi le contact direct par les employeurs.<br/> Nous vous invitons à découvrir nos
        services et à commencer dès aujourd'hui votre recherche d'emploi !
      </Text>
      <Row margin="25px 0 0 0" justifyContent="center" width="100%">
        <Button.Primary
          width="350px"
          minHeight="45px"
          onClick={(e) => handleClickButton(e, "default")}
        >
          Déposer mon CV
        </Button.Primary>
      </Row>
    </PartColumn>
  )
}

export default Part11
