export const response = async ({
  promise,
  serviceType,
  service,
}: {
  promise: any
  serviceType: string
  service: string
}): Promise<any> => {
  try {
    const res = await promise.json()

    return {
      ...res,
      status: promise.status,
      message: res.message,
    }
  } catch {
    return {
      status: promise.status,
    }
  }
}
