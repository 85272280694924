import React, { useContext, useState } from 'react'
import { Modal } from 'antd'

import { authUtils } from 'utils'
import Login from 'components/login'
import { JobOffer } from 'types'
import { jobOfferApplicationsServices } from 'services'
import { UserContext } from 'providers'

import sendImg from 'assets/illustrations/send.jpg'

import { Button, Text, Row } from 'styles/global'
import { Illustration } from './style'
import { routes } from 'router'
import { Link } from 'react-router-dom'
import { ACCOUNT_TYPES } from 'constants/index'

interface CustomModalProps {
  buttonElement: React.ReactElement
  jobOffer: JobOffer
}

const ApplyToJobOffer: React.FC<CustomModalProps> = ({ buttonElement, jobOffer }) => {
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false)
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')
  const { user } = useContext(UserContext)

  const showModal = () => {
    setIsSuccessModalVisible(true)
  }

  const handleCancel = () => {
    setIsSuccessModalVisible(false)
  }

  const handleApplyToJobOffer = (e) => {
    if (!authUtils.isAuth()) {
      e.preventDefault()
      e.stopPropagation()
      setIsLoginFormDisplayed(location.pathname + location.search)
    } else if (jobOffer.originUrl) {
      window.open(jobOffer.originUrl, '_blank', 'noreferrer')
    } else {
      jobOfferApplicationsServices
        .newApplication(
          { candidateId: user._id, candidateName: user.candidateInformations?.name || '' },
          { jobOfferId: jobOffer._id },
        )
        .then((res) => {
          showModal()
        })
    }
  }

  if (authUtils.currentRole() === ACCOUNT_TYPES.COMPANY) {
    return <div></div>
  }

  return (
    <div>
      {isLoginFormDisplayed && (
        <Login
          isDisplayed={isLoginFormDisplayed}
          setIsDisplayed={setIsLoginFormDisplayed}
          isRegistration={true}
        />
      )}
      {React.cloneElement(buttonElement, { onClick: (e) => handleApplyToJobOffer(e) })}
      <Modal footer={[]} visible={isSuccessModalVisible} onCancel={handleCancel}>
        <Illustration src={sendImg} alt="send illustration" />
        <Text size="24px" weight="600">
          Candidature envoyée avec succès !
        </Text>
        <Text position="center" margin="30px 0">
          Pensez à vérifier votre boîte mail afin d'être tennu au courant de la suite
        </Text>
        <Row justifyContent="center">
          <Link style={{ width: 'auto' }} to={routes.myApplications.path}>
            <Button.Third>Voir mes candidatures</Button.Third>
          </Link>
        </Row>
      </Modal>
    </div>
  )
}

export default ApplyToJobOffer
