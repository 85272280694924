import React, { Dispatch, SetStateAction } from 'react'
import { H3, Row, Text, Button } from 'styles/global'

import { PartColumn } from '../style'

const Part6 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-5">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Conseils utiles pour votre recherche d'emploi
      </H3>
      <Text margin="15px 0">
        Outre la mise en relation entre candidats et recruteurs, nous nous efforçons de fournir des
        ressources utiles pour aider les candidats dans leur recherche d'emploi. Que ce soit des
        conseils pour la rédaction de votre CV ou des informations sur les tendances du{' '}
        <strong>marché de l'emploi dans le secteur dentaire</strong>, nous sommes là pour vous
        accompagner à chaque étape du processus.
        <br />
        <br /> En ce sens, emploi-dentiste est plus qu'un simple site d'offres d'emploi. C'est un
        véritable partenaire dans votre recherche d'emploi dentiste ou d'emploi assistante dentaire.
      </Text>
      <Row margin="30px 0 0 0" width="100%" justifyContent="center">
        <a target="_blank" rel="noreferrer" href="https://blog.emploi-dentiste.fr">
          <Button.Third minWidth="450px" responsive="margin-top: 30px;">
            Consulter notre blog destiné aux dentistes
          </Button.Third>
        </a>
      </Row>
    </PartColumn>
  )
}

export default Part6
