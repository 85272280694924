import React from 'react'
import { H2, Text } from 'styles/global'

import { PartColumn } from '../style'

const Part7 = () => {
  return (
    <PartColumn id="title-6">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Un espace optimisé pour votre recrutement dentiste
      </H2>
      <Text margin="15px 0">
        Publier une offre d’emploi dentiste pertinente et efficace est un véritable challenge. Cette
        affirmation prend tout son sens lorsque l'on considère la complexité et l'importance du{' '}
        <strong>processus de recrutement dans le domaine dentaire</strong>. Chez emploi-dentiste, nous
        comprenons cette réalité et nous nous engageons à vous accompagner dans cette démarche
        essentielle pour la croissance de votre cabinet.
      </Text>
    </PartColumn>
  )
}

export default Part7
