import { JobOfferServices } from 'types/api'
import { PATHS, ROUTE, getHeader, response } from 'api'

const login: JobOfferServices = {
  create: async (body, { userId }) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER.DEFAULT)}`, {
      method: 'POST',
      body: JSON.stringify({ ...body, userId }),
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'create' })
  },
  validPayment: async ({ sessionId, userId, email }) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER.DEFAULT)}/valid-payment`, {
      method: 'POST',
      body: JSON.stringify({ sessionId, userId, email }),
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'validPayment' })
  },
  find: async ({ page, region, country, city, department, contractType, jobType, companyId }) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(
      `${ROUTE(PATHS.JOB_OFFER.DEFAULT)}?page=${page || ''}&city=${city || ''}&region=${
        region || ''
      }&country=${country || ''}&department=${department || ''}&contractType=${
        contractType || ''
      }&jobType=${jobType || ''}&companyId=${companyId || ''}`,
      {
        method: 'GET',
        headers,
      },
    )
    return await response({ promise, serviceType: 'jobOffer', service: 'find' })
  },
  findOne: async ({ id }) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER.DEFAULT)}/${id}`, {
      method: 'GET',
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'find' })
  },
  companyOffers: async ({ id }) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER.DEFAULT)}/company/${id}`, {
      method: 'GET',
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'find' })
  },
  update: async (body, { id }) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER.DEFAULT)}/${id}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'find' })
  },
  addView: async ({ id }) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.JOB_OFFER.DEFAULT)}/view/${id}`, {
      method: 'PUT',
      body: JSON.stringify({}),
      headers,
    })
    return await response({ promise, serviceType: '', service: '' })
  },
}

export default login
