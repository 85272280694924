import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img7.svg'
import { Img2, PartColumn } from '../style'
import { routes } from 'router'

const Part9 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-8">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Nous offrons une interface simple et intuitive
          </H3>
          <Text margin="15px 0">
            Vous pouvez consulter les <strong>offres emploi dentiste</strong> et{' '}
            <strong>offres emploi assistante dentaire</strong>
            disponibles en quelques clics seulement. Vous pouvez également filtrer les offres par
            région, type de contrat (CDD, CDI…), et niveau d'expérience requis, ce qui vous permet
            de trouver rapidement les offres qui correspondent à vos critères. Que vous souhaitiez
            consulter les offres d'emploi, déposer votre CV ou créer une alerte emploi, vous pouvez
            le faire rapidement et facilement.
          </Text>
        </Column>
        <Column responsive="width: 100%;" width="30%">
          <Img2 style={{ width: '100%', marginBottom: '20px' }} src={Img} alt="interface simple" />
        </Column>
      </Row>
      <Row width="100%" justifyContent="center">
        <Button.Primary
          margin="20px 0 0 0"
          minWidth="650px"
          responsive="min-width: auto;"
          onClick={(e) => handleClickButton(e, 'default')}
        >
          Consulter les offres d’emplois
        </Button.Primary>
      </Row>
    </PartColumn>
  )
}

export default Part9
