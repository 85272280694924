import { GlobalServices } from 'types/api'
import { PATHS, ROUTE, getHeader, response } from 'api'

const global: GlobalServices = {
  contact: async (body) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.GLOBAL.CONTACT)}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: 'jobOffer', service: 'create' })
  },
}

export default global
