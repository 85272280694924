import React, { useEffect, useState } from 'react'
import { authUtils } from 'utils'
import { ACCOUNT_TYPES } from 'constants/index'
import { jobOfferService } from 'services'

interface Props {
  jobOfferId: string
}

const ViewCounter = ({ jobOfferId }: Props) => {
  const [isAlreadySet, setIsAlreadySet] = useState<boolean>(false)
  useEffect(() => {
    if (authUtils.currentRole() !== ACCOUNT_TYPES.COMPANY && jobOfferId && !isAlreadySet) {
      setIsAlreadySet(true)
      jobOfferService.addView({ id: jobOfferId })
    }
  }, [jobOfferId])

  return <></>
}

export default ViewCounter
