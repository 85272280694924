import React from 'react'
import { Column, H2, Row, Text } from 'styles/global'

import Img from 'assets/landings/candidat/img8.svg'
import { Img3, PartColumn } from '../style'

const Part12 = () => {
  return (
    <PartColumn blue id="title-11">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Comment fonctionne notre plateforme d'offre emploi dentiste ?
      </H2>
      <Row wrap width="100%" justifyContent="space-between">
          <Text margin="15px 0">
            Notre plateforme a été pensée et conçue pour être facile à utiliser, tout en offrant une
            expérience utilisateur optimale. Que vous soyez à la{' '}
            <strong>recherche d'un emploi dentiste</strong> ou d'un{' '}
            <strong>emploi assistante dentaire</strong>, voici comment nous pouvons vous aider à
            trouver le job de vos rêves.
          </Text>
      </Row>
    </PartColumn>
  )
}

export default Part12
