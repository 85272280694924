/* eslint-disable */
// @ts-nocheck

import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { message, Checkbox, InputNumber } from 'antd'
import { loadStripe } from '@stripe/stripe-js'
import { Editor } from 'react-draft-wysiwyg'
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import { Form, Button, Text, Input, Title, Row, Column } from 'styles/global'
import { Company } from 'types'
import { userService } from 'services'
import {
  CountrysSelect,
  RegionSelect,
  DepartmentsSelect,
  UploadImage,
  NavigationBlocker,
} from 'components'
import { UserContext } from 'providers/UserProvider'
import { routes } from 'router'
import { useCallbackPrompt } from 'hooks'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '')
let stripe
stripePromise.then((resStripe) => (stripe = resStripe))

type File = {
  base64: string
  name: string
}

const InfromationsForm = ({
  setIsLoading,
}: {
  setIsLoading?: Dispatch<SetStateAction<boolean>>
}) => {
  const [companyInformations, setCompanyInformations] = useState<Company>({
    name: '',
    email: '',
    phone: '',
    description: '',
    profilePicture: '',
    address: {
      street: '',
      city: '',
      postalCode: '',
      country: '',
    },
  })
  const [profilePicture, setProfilePicture] = useState<any>([])
  const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
  const [isDefaultDatasLoad, setIsDefaultDatasLoad] = useState(false)
  const [isChange, setIsChange] = useState<boolean>(false)
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(isChange)
  const { user, setUser } = useContext(UserContext)

  const editor = React.useRef(null)

  useEffect(() => {
    if (user.companyInformations) {
      setCompanyInformations(user.companyInformations)
      const defaultProfilePicture = user.companyInformations?.profilePicture
      if (defaultProfilePicture) {
        setProfilePicture([{ base64: defaultProfilePicture, name: 'profilePicture.png' }])
      }
      const defaultDescription = user.companyInformations?.description
      if (defaultDescription) {
        setEditorState(
          EditorState.createWithContent(convertFromRaw(JSON.parse(defaultDescription))),
        )
      }
    }
  }, [user])

  useEffect(() => {
    if (!isDefaultDatasLoad && user._id) {
      setIsDefaultDatasLoad(true)
    }
  }, [companyInformations, user])

  const onObjectChange = (attr1: string, attr2: string, value: string) => {
    if (!isChange) {
      setIsChange(true)
    }
    setCompanyInformations({
      ...companyInformations,
      [attr1]: { ...companyInformations[attr1], [attr2]: value },
    })
  }

  const handleChange = (attr, value) => {
    if (!isChange) {
      setIsChange(true)
    }
    setCompanyInformations({ ...companyInformations, [attr]: value })
  }

  const handleSave = () => {
    if (setIsLoading) {
      setIsLoading(true)
    }
    const describeContent = editorState.getCurrentContent()
    const description = JSON.stringify(convertToRaw(describeContent))
    userService
      .update({
        companyInformations: {
          ...companyInformations,
          profilePicture: profilePicture[0]?.base64 || companyInformations.profilePicture || '',
          email: companyInformations.email || user.email,
          description,
        },
      })
      .then((res) => {
        if (setIsLoading) {
          setIsLoading(false)
        }
        if (res.status === 200) {
          setIsChange(false)
          userService.me().then((res) => setUser(res))
          message.success('Informations sauvegardées avec succés !')
        }
      })
  }

  return isDefaultDatasLoad ? (
    <Form
      responsive="width: 95vw; max-width: 850px;"
      width="550px"
      margin="50px 0 0 0"
      layout="vertical"
      onFinish={handleSave}
      isStyled
      id="candidat-informations-form"
      initialValues={{
        name: companyInformations.name,
        email: companyInformations.email || user.email,
        street: companyInformations.address?.street,
        city: companyInformations.address?.city,
        postalCode: companyInformations.address?.postalCode,
        country: companyInformations.address?.country,
      }}
    >
      <NavigationBlocker
        // @ts-ignore
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
        saveCallback={handleSave}
      />
      <Title margin="0 0 20px 0" color="secondary">
        Les informations de mon entreprise
      </Title>
      <Column width="100%" alignItems="start">
        <Text margin="10px 0 10px 0" weight="bold">
          Photo de profil
        </Text>
        <UploadImage fileList={profilePicture} setFileList={setProfilePicture} maxImages={1} />
        <Form.Item
          style={{ width: '100%', marginTop: '20px' }}
          name="name"
          onChange={(e) => handleChange('name', e.target.value)}
          label="Nom de votre entreprise"
          rules={[{ required: true, message: 'Veuillez renseigner votre nom de entreprise' }]}
        >
          <Input maxLength={35} width="100%" />
        </Form.Item>
        <Text
          style={{ color: 'grey' }}
          width="100%"
          position="start"
          margin="10px 0 15px 0"
          size="16px"
        >
          Adresse de votre entreprise <br />
        </Text>
        <Form.Item
          style={{ width: '100%' }}
          name="street"
          onChange={(e) => onObjectChange('address', 'street', e.target.value)}
          label="Rue et numéro de rue"
          rules={[{ required: true, message: 'Veuillez renseigner votre rue' }]}
        >
          <Input defaultValue={companyInformations.address?.street} maxLength={35} width="100%" />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name="city"
          onChange={(e) => onObjectChange('address', 'city', e.target.value)}
          label="Ville"
          rules={[{ required: true, message: 'Veuillez renseigner votre ville' }]}
        >
          <Input defaultValue={companyInformations.address?.city} maxLength={35} width="100%" />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name="postalCode"
          onChange={(e) => onObjectChange('address', 'postalCode', e.target.value)}
          label="Code postal"
          rules={[{ required: true, message: 'Veuillez renseigner votre code postal' }]}
        >
          <Input
            defaultValue={companyInformations.address?.postalCode}
            maxLength={35}
            width="100%"
          />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name="country"
          onChange={(e) => onObjectChange('address', 'country', e.target.value)}
          label="Pays"
          rules={[{ required: true, message: 'Veuillez renseigner votre pays' }]}
        >
          <Input defaultValue={companyInformations.address?.country} maxLength={35} width="100%" />
        </Form.Item>
        <Text
          style={{ color: 'grey' }}
          width="100%"
          position="start"
          margin="10px 0 15px 0"
          size="16px"
        >
          Contacts <br />
        </Text>
        <Form.Item
          style={{ width: '100%' }}
          name="email"
          onChange={(e) => handleChange('email', e.target.value)}
          label="Adresse e-mail"
          rules={[
            {
              required: true,
              message: 'Veuillez renseigner une adresse e-mail',
            },
          ]}
        >
          <Input maxLength={100} width="100%" />
        </Form.Item>
        <Form.Item
          style={{ width: '100%' }}
          name="phone"
          onChange={(e) => handleChange('phone', e.target.value)}
          label="Numéro de téléphone"
        >
          <Input maxLength={18} defaultValue={companyInformations.phone} width="100%" />
        </Form.Item>

        <Form.Item
          name="description"
          onChange={(e) => handleChange('description', e.target.value)}
          label="Description de votre entreprise"
        >
          <Editor
            ref={editor}
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={(editorState) => setEditorState(editorState)}
            toolbar={{
              options: [
                'inline',
                'list',
                'textAlign',
                'colorPicker',
                'link',
                'emoji',
                'image',
                'remove',
                'history',
              ],
            }}
          />
        </Form.Item>
      </Column>
      <Column margin="15px 0 0 0" alignItems="center">
        <Button.Primary
          type="primary"
          htmlType="submit"
          weight="bold"
          fontSize="18px"
        >
          Enregistrer mes information
        </Button.Primary>
        <Link
          style={{ marginTop: '15px', fontSize: '16px', textDecoration: 'underline' }}
          to={`${routes.companyProfile.path}?companyId=${user._id}`}
        >
          Voir le rendu de mon profil
        </Link>
      </Column>
    </Form>
  ) : (
    <></>
  )
}

export default InfromationsForm
