import { InputButton } from 'components'
import React, { Dispatch, SetStateAction } from 'react'
import { theme } from 'styles'
import { Column, H1, Row, Text } from 'styles/global'

import Img from 'assets/landings/company/img1.svg'
import { PartRow, Img1 } from '../style'

const Part1 = ({
  handleClickButton,
  setDefaultLoginEmail,
}: {
  handleClickButton: (e: any, path: string) => void
  setDefaultLoginEmail: Dispatch<SetStateAction<string>>
}) => {
  return (
    <PartRow wrap justifyContent="space-between" blue>
      <Column responsive="width: 95%" width="50%">
        <H1 weight="500" size="22px">
          Recrutement dentiste
        </H1>
        <Text margin="15px 0">
          Rejoignez la première plateforme spécialisée en recrutement dans le secteur dentaire.
          Avec une audience ultra qualifiée, chacune des offres d'emploi sur emploi-dentiste.fr est
          consultée par plusieurs centaines de praticiens intéressés.
        </Text>
        <InputButton
          callback={(value, e) => {
            setDefaultLoginEmail(value)
            handleClickButton(e, 'default')
          }}
          buttonContent="Publier une offre d'emploi"
          placeholder="Adresse email"
        />
        <Text margin="15px 0 0 0" weight="300">
          Inscrivez vous gratuitement afin d’accéder aux profils dentiste et publier vos offres
          d’emploi
        </Text>
      </Column>
      <Img1 src={Img} alt="recrutement dentiste" />
    </PartRow>
  )
}

export default Part1
