import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Title, Row, Column, Button, Text, H1, H2, H3, H4 } from 'styles/global'
import { routes } from 'router'
import { CreateEditJobOfferForm, Loader } from 'components'
import { InformationsForm } from 'components/companyAccount'
import { urlParams } from 'utils'
import { message } from 'antd'
import { jobOfferService } from 'services'
import { useAuth } from 'hooks'

const CompanyAccount = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { logout } = useAuth()

  const navigate = useNavigate()

  return (
    <Column width="100%" alignItems="center">
      <Helmet>
        <title>emploi-dentiste | votre profil entreprise</title>
        <meta name="description" content="Accédez à votre profil entreprise emploi-dentiste" />
      </Helmet>
      <InformationsForm setIsLoading={setIsLoading} />
      <Button.Primary margin="40px 0 0 0" onClick={logout}>
        Se déconnecter
      </Button.Primary>
    </Column>
  )
}

export default CompanyAccount
