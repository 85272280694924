/* eslint-disable */

import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { articles } from '../../pages/blog/articles'
import { Text, Row } from 'styles/global'

import './style.css'

const ArticleCard = ({ article, setDisplayedArticles, setSelectedTag }) => {
  const navigate = useNavigate()

  const handleClickTag = (tag, e) => {
    e.stopPropagation()
    e.preventDefault()
    setSelectedTag(tag)
    navigate(tag ? `/blog?tag=${tag}` : '/blog')
    if (setDisplayedArticles) {
      setDisplayedArticles(articles.filter((article) => article.tags.includes(tag) || !tag))
    }
  }

  return (
    <Link to={article.pathname} className="column blog-article-card">
      <img
        className="blog-article-card-img"
        src={`${process.env.REACT_APP_URL}/${article.imagePath}`}
        alt={article.title}
      />
      <h2
        style={{ marginTop: '10px', marginBottom: '5px' }}
        className="title-bold montserrat start"
      >
        {article.title}
      </h2>
      <Text size="12px" style={{ marginBottom: '10px', fontSize: '14px' }}>
        {article.description}
      </Text>
      <Link style={{ fontSize: '18px' }} className="text-300 blue" to={article.pathname}>
        Lire l'article
      </Link>
      <Row wrap width="100%">
        {article.tags.map((tag) => (
          <p onClick={(e) => handleClickTag(tag.toUpperCase(), e)} className="blog-tag">
            {tag}
          </p>
        ))}
      </Row>
    </Link>
  )
}

export default ArticleCard
