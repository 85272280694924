import React from 'react'

import { Select } from 'styles/global'
import { cities } from 'datas/cities'

const { Option } = Select

type Props = {
  setSelectedCity: any
  isMultiple?: boolean
  style?: any
  defaultValue?: any
}

class CitySelect extends React.Component<Props, {selectedCity: string, newOption: string, options: string[]}> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedCity: props.defaultValue || '',
      newOption: '',
      options: cities,
    };
  }

  handleCityChange = (value: string) => {
    if (value === '0') {
      this.setState({ selectedCity: '0' });
    } else {
      this.setState({ selectedCity: value });
      this.props.setSelectedCity(value);
    }
  };

  handleSearch = (value: string) => {
    if (value.length > 0 && !this.state.options.includes(value)) {
      this.setState({ newOption: value });
    }
  };

  render() {
    const cityOptions = this.state.options.map((city, index) => (
      <Option key={index.toString()} value={city}>
        {city}
      </Option>
    ));

    return (
      <Select
        value={this.state.selectedCity || undefined}
        mode={this.props.isMultiple ? 'tags' : undefined}
        allowClear
        showSearch
        width="150px"
        responsive="width: 100%;"
        style={this.props.style}
        placeholder="Ville"
        optionFilterProp="children"
        onChange={this.handleCityChange}
        onSearch={this.handleSearch}
        filterOption={(input, option) =>
          (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
        }
      >
        <Option value={'*'}>Toutes les villes</Option>
        {this.state.newOption && (
          <Option key={this.state.newOption} value={'0'}>
            {this.state.newOption}
          </Option>
        )}
        {cityOptions}
      </Select>
    );
  }
}

export default CitySelect