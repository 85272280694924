import React, { Dispatch, SetStateAction } from 'react'
import { Column, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img2.svg'
import { Img3, PartColumn } from '../style'
import { routes } from 'router'

const Part2 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-1">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Une plateforme dédiée à l'emploi de dentiste
      </H2>
      <Row wrap justifyContent="space-between">
        <Img3 src={Img} alt="plateforme emploi dentiste" />
        <Column responsive="width: 100%;" width="60%">
          <Text margin="15px 0">
            Bienvenue sur emploi-dentiste, notre plateforme dédiée à la{' '}
            <strong>recherche d'emploi dentiste</strong>
            et à la <strong>recherche d’emploi assistante dentaire</strong>. En tant que
            spécialistes du <strong>recrutement dans le secteur dentaire</strong>, nous avons pour
            mission de faciliter la mise en relation entre les candidats à la recherche d'un emploi
            et les cabinets et recruteurs qui cherchent à compléter leurs équipes.
          </Text>
          <Button.Primary onClick={(e) => handleClickButton(e, routes.viewOffers.path)}>
          Consulter les offres d’emploi
          </Button.Primary>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part2
