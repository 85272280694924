import React, { Dispatch, SetStateAction } from 'react'
import { H3, Row, Text, Button } from 'styles/global'

import { PartColumn } from '../style'

const Part17 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-16">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Une approche centrée sur la qualité
      </H3>
      <Text margin="15px 0">
        Cette mission, nous l'accomplissons grâce à notre approche centrée sur la qualité et la
        pertinence. Nous croyons que chaque offre d’emploi mérite d'atteindre son public cible,
        c’est pourquoi nous travaillons sans relâche pour nous assurer qu’elles soient vues par les
        bonnes personnes, celles qui sont réellement intéressées et qualifiées. Ainsi, en utilisant
        notre plateforme, vous pouvez être sûr d’augmenter vos chances de trouver le candidat idéal.
        <br />
        <br />
        Emploi-dentiste, c'est plus qu'une simple plateforme de recrutement. C'est un véritable
        partenaire qui vous accompagne tout au long de votre processus de recherche de candidat. De
        la publication de votre offre d'emploi à la sélection du professionnel idéal en passant par
        l'optimisation de votre annonce et le tri des candidatures, nous sommes là pour vous aider à
        chaque étape.
      </Text>
      <Row responsive="width: 100%;" margin="25px 0 0 0" justifyContent="center" width="100%">
        <Button.Primary
          width="350px"
          minHeight="45px"
          onClick={(e) => handleClickButton(e, 'default')}
        >
          Publier une offre d’emploi
        </Button.Primary>
      </Row>
    </PartColumn>
  )
}

export default Part17
