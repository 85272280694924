import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { Container, ContentContainer } from './style'
import { H1, Text, Table, Button } from 'styles/global'
import { EmptyTableMessage, Skeletons } from 'components'

import { jobOfferApplicationsServices } from 'services'
import { useQuery } from 'react-query'
import { parseDate } from 'utils'
import { UserContext } from 'providers'
import { routes } from 'router'
import { Link } from 'react-router-dom'

const MyApplications = () => {
  const { user } = useContext(UserContext)

  const {
    isLoading,
    data: jobOfferApplications,
    isSuccess: isDatasLoad,
  } = useQuery(['myApplications', user._id], () =>
    jobOfferApplicationsServices.findByCandidate().then((res) => {
      return res.data
    }),
  )

  const columns = [
    {
      title: 'Date de candidature',
      dataIndex: 'date',
      key: 'date',
      render: (text) => <Text>{parseDate(text)}</Text>,
      sorter: {
        compare: (a, b) => a.date?.localeCompare(b.date),
      },
    },
    {
      title: 'Entreprise',
      dataIndex: 'companyName',
      key: 'companyName',
      render: (text) => <Text>{text}</Text>,
      sorter: {
        compare: (a, b) => a.companyName?.localeCompare(b.companyName),
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Link
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
          to={`${routes.jobOffer.path}?id=${record.jobOfferId}`}
        >
          <Button.Third>Voir l'annonce</Button.Third>
        </Link>
      ),
    },
  ]

  return (
    <Container>
      <Helmet>
        <title>Retrouver ses candidatures</title>
        <meta
          name="description"
          content="Retrouver mes candidatures aux offres d'emplois ambulancier (DEA et AA)"
        />
      </Helmet>
      <H1
        size="30px"
        style={{ marginTop: '30px', maxWidth: '90vw', marginBottom: '30px' }}
        weight="bold"
        position="center"
        color="secondary"
      >
        Mes candidatures
      </H1>
      <ContentContainer responsive="width: 100%;" width="80%">
        {isLoading ? (
          <Skeletons />
        ) : (
          <Table
            locale={{
              emptyText: (
                <EmptyTableMessage
                  link={routes.viewOffers.path}
                  messageLink="Voir les offres d'emploi"
                />
              ),
            }}
            pagination={false}
            scroll={{ x: 630, y: 600 }}
            columns={columns}
            dataSource={jobOfferApplications || []}
          />
        )}
      </ContentContainer>
    </Container>
  )
}

export default MyApplications
