import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/company/img4.svg'
import { Img2, PartColumn } from '../style'

const Part4 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-3">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Une solution recrutement dentiste sur mesure
      </H3>
      <Row width="100%" wrap justifyContent="space-between">
        <Column responsive="width: 100%; margin-bottom: 20px;" width="35%">
          <Img2
            style={{ width: '100%', marginBottom: '20px' }}
            responsive="width: calc(100% - 10vw) !important;"
            src={Img}
            alt="publier une offre"
          />
          <Button.Third width="100%" onClick={(e) => handleClickButton(e, 'default')}>
            Publier une offre d’emploi
          </Button.Third>
        </Column>
        <Column responsive="width: 100%;" width="60%">
          <Text margin="15px 0">
            Notre mission ? Vous offrir une solution sur mesure capable de répondre aux besoins de
            votre entreprise en matière de recrutement. Ainsi, notre plateforme dédiée permet non
            seulement de publier des{' '}
            <strong>annonces de recrutement pour tous métiers du secteur dentaire</strong>, mais
            également d'optimiser votre processus de recrutement grâce à des services
            complémentaires.
            <br />
            <br /> Notre site web est donc conçu pour les dentistes qui recherchent de nouvelles
            opportunités professionnelles. Nous proposons un véritable carrefour pour les
            professionnels du secteur dentaire, aussi bien pour les candidats que pour les cabinets
            et recruteurs. Qu'il s'agisse de dentistes expérimentés à la recherche de nouveaux
            défis, d'assistantes dentaires désireuses d'élargir leur champ d'action ou de jeunes
            diplômés prêts à faire leurs premiers pas dans la profession, nous satisfaisons un
            public diversifié et qualifié.
          </Text>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part4
