import React, { Dispatch, SetStateAction } from 'react'
import { H3, Row, Text, Button } from 'styles/global'

import { PartColumn } from '../style'
import { routes } from 'router'

const Part14 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-13">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Trouvez le professionnel dentaire dont vous avez besoin
      </H3>
      <Text margin="15px 0">
        Notre objectif est simple : nous voulons vous aider à{' '}
        <strong>trouver les professionnels dentaires</strong>
        dont vous avez besoin pour votre établissement. Que vous cherchiez à{' '}
        <strong>recruter une assistante dentaire ou un dentiste expérimenté</strong>, nous mettons
        tout en œuvre pour faciliter votre recherche et assurer le succès de votre recrutement.{' '}
        <br />
        <br /> Pour nous, chaque recrutement réussi est une affirmation de notre engagement envers
        les professionnels qui nous font confiance ainsi qu’une motivation pour continuer à
        améliorer nos services. Nous sommes votre partenaire privilégié, un allié qui partage vos
        ambitions et s'investit pleinement dans la réalisation de vos objectifs. <br />
        <br /> Alors n'hésitez plus, faites le choix de l'excellence et de l'accompagnement
        personnalisé pour votre <strong>recrutement de dentiste ou d’assistante dentaire</strong>.
        Choisissez emploi-dentiste, et ensemble, faisons de chaque recrutement une réussite.
      </Text>
      <Row wrap margin="30px 0 0 0" width="100%" justifyContent="space-evenly">
        <Button.Primary responsive="margin: 15px;" onClick={(e) => handleClickButton(e, 'default')}>
          Publier une offre d’emploi
        </Button.Primary>
        <Button.Third
          responsive="margin: 15px;"
          onClick={(e) => handleClickButton(e, routes.contact.path)}
        >
          Nous contacter
        </Button.Third>
      </Row>
    </PartColumn>
  )
}

export default Part14
