import React, { useContext } from 'react'
import { DownOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Dropdown, Space } from 'antd'

import { UserContext } from 'providers'
import { useAuth } from 'hooks'
import { Row, Text } from 'styles/global'
import { ACCOUNT_TYPES } from 'constants/index'
import { authUtils } from 'utils'
import { routes } from 'router'
import { Link } from 'react-router-dom'
import { ProfilPicture, MyProfilContainer } from './style'

import profilImg from 'assets/icons/profile.svg'
import companyProfilImg from 'assets/icons/building.svg'

const MyProfil = () => {
  const { user } = useContext(UserContext)
  const { logout } = useAuth()


  if (!user._id) {
    return <></>
  }

  let profilPicture =
    user.candidateInformations?.profilePicture || user.companyInformations?.profilePicture

  let items: MenuProps['items'] = [
    {
      type: 'divider',
    },
    {
      key: '1',
      label: <Text onClick={logout}>Déconnexion</Text>,
    },
  ]

  if (authUtils.currentRole() === ACCOUNT_TYPES.CANDIDATE) {
    items = [
      {
        key: '2',
        label: <Link to={routes.candidateAccount.path}>Modifier mes informations</Link>,
      },
      {
        key: '3',
        label: <Link to={routes.myApplications.path}>Mes candidatures</Link>,
      },
      ...items,
    ]

    if (!profilPicture) {
      profilPicture = profilImg
    }
  }

  if (authUtils.currentRole() === ACCOUNT_TYPES.COMPANY) {
    items = [
      {
        key: '2',
        label: <Link to={routes.companyAccount.path}>Mon profil entreprise</Link>,
      },
      ...items,
    ]
    if (!profilPicture) {
      profilPicture = companyProfilImg
    }
  }

  return (
    <MyProfilContainer>
      <Dropdown trigger={['click']} menu={{ items }}>
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <Row alignItems="center">
              <ProfilPicture src={profilPicture} />
              <Text>
                Mon compte
              </Text>
            </Row>
            <DownOutlined />
          </Space>
        </a>
      </Dropdown>
    </MyProfilContainer>
  )
}

export default MyProfil
