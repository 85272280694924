import React from 'react'
import { H3, Text } from 'styles/global'

import { PartColumn } from '../style'

const Part10 = () => {
  return (
    <PartColumn id="title-11">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Un partenaire qui s’engage à vos côtés
      </H3>
      <Text margin="15px 0">
        Lorsque vous choisissez de travailler avec nous, vous ne choisissez pas simplement une
        plateforme de recrutement, vous choisissez un partenaire qui s'engage à vos côtés, prêt à
        vous soutenir à chaque étape du processus de recrutement. Dès le début, nous prenons le
        temps nécessaire pour comprendre vos besoins spécifiques, vos objectifs de recrutement et
        vos attentes en matière de candidats. Cette compréhension nous permet de vous offrir un
        service sur mesure, adapté à vos attentes.
      </Text>
    </PartColumn>
  )
}

export default Part10
