import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img4.svg'
import { Img2, PartColumn } from '../style'

const Part4 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-3">
      <Row width="100%" wrap justifyContent="space-between">
        <Column responsive="width: 100%; margin-bottom: 20px;" width="35%">
          <Img2
            style={{ width: '100%', marginBottom: '20px' }}
            responsive="width: calc(100% - 10vw) !important;"
            src={Img}
            alt="interface saas"
          />
        </Column>
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
            Une interface intuitive
          </H3>
          <Text margin="15px 0">
            Nous sommes fiers de proposer une interface intuitive et facile à utiliser. En quelques
            clics, vous pouvez accéder à une multitude d'<strong>offres d’emploi dentiste</strong>{' '}
            et <strong>offres d’emploi assistante dentaire</strong>. Ces offres sont soigneusement
            sélectionnées et régulièrement mises à jour pour garantir leur pertinence. Nous
            travaillons en étroite collaboration avec des cabinets dentaires, des cliniques et des
            entreprises du secteur dentaire à travers toute la France, ce qui nous permet de
            proposer une grande variété d'opportunités professionnelles.
          </Text>
          <Button.Third onClick={(e) => handleClickButton(e, 'default')}>
            Accéder aux outils emploi-dentiste.fr
          </Button.Third>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part4
