import { InputButton } from 'components'
import React, { Dispatch, SetStateAction } from 'react'
import { Column, H2, Text } from 'styles/global'

import Img from 'assets/landings/candidat/img1.svg'
import { PartRow, Img1 } from '../style'
import { routes } from 'router'
import { Link } from 'react-router-dom'

const CandidatPart = ({
  handleClickButton,
  setDefaultLoginEmail,
}: {
  handleClickButton: (e: any, path: string) => void
  setDefaultLoginEmail: Dispatch<SetStateAction<string>>
}) => {
  return (
    <PartRow wrap justifyContent="space-between">
      <Img1 src={Img} alt="Recherche emploi dentiste" />
      <Column responsive="width: 95%" width="50%">
        <H2 weight="500" size="22px">
          Emploi-dentiste : Votre partenaire offres d'emploi dentiste
        </H2>
        <Text margin="15px 0 15px 0" weight="300">
          Inscrivez vous gratuitement afin d’accéder aux offres d’emploi et partager votre profil aux
          recruteurs
        </Text>
        <InputButton
          callback={(value, e) => {
            setDefaultLoginEmail(value)
            handleClickButton(e, 'default')
          }}
          buttonContent="S’inscrire"
          placeholder="Adresse email"
        />
      </Column>
      <Text width="100%" margin="15px 0">
        Emploi-dentiste est plus qu'une simple plateforme de recrutement, c'est un véritable
        écosystème conçu pour répondre aux besoins spécifiques des{' '}
        <strong>professionnels du secteur dentaire</strong>. Que vous soyez un jeune diplômé à la
        recherche de votre premier emploi, un dentiste expérimenté à la recherche d'un nouveau défi
        ou un professionnel du secteur dentaire en quête d'une opportunité de carrière
        enrichissante, vous trouverez sur notre plateforme une large{' '}
        <Link style={{ fontWeight: '500', textDecoration: 'underline' }} to={routes.viewOffers.path}>
          sélection d'offre d'emploi{' '}
        </Link>
        qui sauront répondre à vos attentes.
        <br />
        <br /> Nous ne proposons que des{' '}
        <strong>
          offres d'emploi spécifiquement dédiées aux dentistes et aux autres travailleurs du secteur
          dentaire
        </strong>
        . Vous trouverez un large éventail de postes en CDI et en CDD, que vous soyez assistante
        dentaire, dentiste associé, spécialiste en orthodontie ou en parodontologie. Notre objectif
        est de vous offrir le plus grand choix possible pour vous aider à trouver l'emploi qui vous
        correspond le mieux.
        <br />
        <br /> Mais nous ne nous contentons pas de rassembler les offres d'emploi. Nous nous
        efforçons également de faciliter votre recherche. Sur emploi-dentiste, vous pouvez explorer
        les offres d'emploi par région et par département. Que vous cherchiez un poste dans une
        grande ville ou dans une petite commune, vous pouvez facilement trouver propositions
        disponibles dans la zone géographique de votre choix.
        <br />
        <br /> En outre, vous avez la possibilité de naviguer sur les pages dédiées aux{' '}
        <strong>entreprises du secteur dentaire</strong> et aux cabinets qui sont actuellement en
        phase de recrutement. Cela vous offre l'opportunité d'obtenir des informations détaillées
        sur les employeurs potentiels avant de postuler. Ainsi, vous pouvez avoir une idée claire de
        l'environnement de travail, de la culture d'entreprise et des valeurs qui animent les
        différentes sociétés.
        <br />
        <br /> Nous savons à quel point il est important d'avoir accès à des offres d'emploi à jour
        et pertinentes. C'est pourquoi nous nous engageons à mettre régulièrement à jour toutes les
        offres pour assurer leur pertinence et leur sérieux. Nous travaillons en étroite
        collaboration avec les recruteurs pour nous assurer que seules les propositions légitimes
        soient publiées.
        <br />
        <br /> Enfin, chez emploi-dentiste, nous croyons en la proactivité. C'est pourquoi nous vous
        offrons la possibilité de déposer votre CV en ligne. Cela permet aux recruteurs de vous
        contacter directement si votre profil correspond à leurs besoins. En déposant votre CV, vous
        augmentez vos chances d'être contacté par des recruteurs à la recherche de talents.
        <br />
        <br /> Emploi-dentiste est donc bien plus qu'un simple site d'emploi, c'est un partenaire
        qui vous accompagne dans votre processus de recherche d'emploi et vous aide à réaliser vos
        ambitions professionnelles dans le secteur dentaire.
      </Text>
    </PartRow>
  )
}

export default CandidatPart
