import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img6.svg'
import { Img2, PartColumn } from '../style'

const Part8 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-7">
      <Row width="100%" wrap justifyContent="space-between">
        <Column responsive="width: 100%; margin-bottom: 30px;" width="30%">
          <Img2
            style={{ width: '100%', marginBottom: '20px' }}
            src={Img}
            alt="secteur dentaire"
          />
        </Column>
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
          Nous sommes spécialisés dans le secteur dentaire
          </H3>
          <Text margin="15px 0">
          Emploi-dentiste est entièrement dédié au secteur dentaire. Cela indique que nous
            maîtrisons les particularités de ce secteur, les aptitudes nécessaires et les obstacles
            rencontrés par les experts en santé dentaire. Cette spécialisation nous permet de vous
            proposer des offres d'emploi qui correspondent précisément à vos compétences et à vos
            aspirations.
          </Text>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part8
