import React, { Dispatch, SetStateAction } from 'react'
import { Column, H3, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/company/img6.svg'
import { Img2, PartColumn } from '../style'

const Part8 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-7">
      <Row width="100%" wrap justifyContent="space-between">
        <Column responsive="width: 100%; margin-bottom: 30px;" width="30%">
          <Img2
            style={{ width: '100%', marginBottom: '20px' }}
            src={Img}
            alt="outils recrutement"
          />
          <Button.Third width="100%" onClick={(e) => handleClickButton(e, 'default')}>
            Découvrir nos outils
          </Button.Third>
        </Column>
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Outils pour faciliter votre recrutement
          </H3>
          <Text margin="15px 0">
            Contrairement à d'autres plateformes spécialisées dans le recrutement, notre mission ne
            se limite pas à la simple mise en relation entre les recruteurs et les candidats. Bien
            que cet aspect soit fondamental, nous estimons qu'il ne suffit pas à garantir un
            processus de recrutement optimal. Pour nous, il s’agit d’un voyage qui nécessite une
            attention particulière. C'est pourquoi nous nous efforçons de vous fournir les outils
            adéquats pour faire de chaque étape une réussite. <br />
            <br /> Ainsi, notre plateforme est spécialement optimisée pour le{' '}
            <strong>recrutement de dentiste</strong>. Grâce à notre interface intuitive et facile à
            utiliser, vous pouvez publier vos annonces en quelques clics seulement. De plus, nous
            proposons un ensemble d'outils avancés qui vous permettent d'affiner et de cibler vos
            recherches, d'analyser et de comparer les CV, de suivre l'évolution des candidatures…
          </Text>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part8
