import React, { useState } from 'react'
import { ButtonInInput, Container } from './style'
import { Input } from 'styles/global'

interface Props {
  callback: (value: string, e: any) => void
  buttonContent: string
  placeholder?: string
}
const InputButton = ({ callback, buttonContent, placeholder }: Props) => {
  const [value, setValue] = useState<string>('')
  return (
    <Container>
      <Input
        style={{ height: '45px' }}
        placeholder={placeholder}
        onChange={(e) => setValue(e.target.value)}
        onPressEnter={(e) => callback(value, e)}
      />
      <ButtonInInput onClick={(e) => callback(value, e)}>{buttonContent}</ButtonInInput>
    </Container>
  )
}

export default InputButton
