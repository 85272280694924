import { styled, theme } from 'styles'
import { Card as CardG, Column } from 'styles/global'

export const Card = styled(CardG)`
  width: 280px;
  height: 350px;
  padding: 0;
  box-shadow: 0px 0px 2px 0px #e0e0e0;
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  &:hover {
    transform: scale(1.03);
  }
`

export const Image = styled.img`
  width: 100%;
  max-height: 160px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  object-fit: cover;
  object-position: top;
`

export const CardInfo = styled.div`
  background-color: #f5f5f5;
  padding: 6px 10px;
  border-radius: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  margin-top: 15px;
`

export const CardInfoBold = styled.span`
  font-weight: bold;
  margin-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const ContentCard = styled(Column)`
  padding: 20px;
  width: 100%;
`
