import React, { useEffect, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Divider } from 'antd'
import { Helmet } from 'react-helmet'

import { Container, Banner } from './style'
import { Row, Button, H1 } from 'styles/global'
import { routes } from 'router'
import { windowSizes } from 'styles/mediaQuery'
import { UserContext } from 'providers'
import { authUtils } from 'utils'
import Login from 'components/login'
import { ACCOUNT_TYPES } from 'constants/index'

import CandidatPart from './components/CandidatPart'
import CompanyPart from './components/CompanyPart'
import { JobOffersHome } from 'components'

const Home = () => {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < windowSizes.mobile)
  const [loginRedirection, setLoginRedirection] = useState<string>('')
  const [defaultLoginEmail, setDefaultLoginEmail] = useState<string>('')
  const navigate = useNavigate()

  const isConnected: boolean = authUtils.isAuth()

  useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < windowSizes.mobile ? setIsMobile(true) : setIsMobile(false),
    )
    return () =>
      window.removeEventListener('resize', () =>
        window.innerWidth < windowSizes.mobile ? setIsMobile(true) : setIsMobile(false),
      )
  }, [])

  const handleClickButton = (e, path) => {
    if (authUtils.isAuth() || path === routes.contact.path) {
      navigate(path === 'default' ? routes.myOffers.path : path)
    } else {
      e.preventDefault()
      e.stopPropagation()
      setLoginRedirection(path)
    }
  }

  return (
    <Container style={{ marginBottom: '-80px' }}>
      {loginRedirection && (
        <Login
          isRegistration={true}
          isDisplayed={loginRedirection}
          setIsDisplayed={setLoginRedirection}
          defaultEmail={defaultLoginEmail}
        />
      )}
      <Helmet>
        <title>
          Plateforme de recrutement et offres d'emploi pour professionnels du secteur dentaire
        </title>
        <meta
          name="description"
          content=" Emploi-dentiste, votre partenaire de recrutement spécialisé dans le secteur dentaire. Découvrez une large sélection d'offres d'emploi pour dentistes et autres professionnels dentaires. Déposez votre CV ou publiez votre offre d’emploi."
        />
        <meta name="keywords" content="dentiste, emploi, recrutement" />
        <script type="application/ld+json">
          {/* BIEN MODIFIER LE NOM DU SITE */}
          {`{"@context": "http://schema.org","@type": "WebSite","name" : "Emploi-Dentiste","url": "https://www.emploi-dentiste.fr"}`}
        </script>
        <script type="application/ld+json">
          {`{"@context" : "http://schema.org", "@type" : "Organization","name" : "Emploi-Dentiste","url" : "https://www.emploi-dentiste.fr","logo": "https://emploi-dentiste.fr/static/media/logo.b8e33088a389ee873185.png"}`}
        </script>
      </Helmet>
      <Banner>
        <H1 margin="0 0 20px 0" style={{ maxWidth: '90vw' }} size="40px" weight="500" color="white">
          Le portail emploi <span style={{ fontWeight: 'bold' }}>dentiste</span>
          <br />
          <span style={{ fontSize: '32px' }}>Pour les entreprises et pour les candidats</span>
        </H1>
        {authUtils.currentRole() !== ACCOUNT_TYPES.CANDIDATE && (
          <Link to={routes.companyHome.path}>
            <Button.Secondary weight="600" fontSize="18px" margin="20px 0 25px 0">
              Services aux recruteurs
            </Button.Secondary>
          </Link>
        )}
        <Row>
          <Button.Secondary weight="600" fontSize="18px" margin="0 20px 0 0">
            <Link to={routes.viewOffers.path}>Offres d’emploi</Link>
          </Button.Secondary>
          {authUtils.currentRole() !== ACCOUNT_TYPES.COMPANY && (
            <Button.Secondary
              onClick={(e) => handleClickButton(e, 'default')}
              weight="600"
              fontSize="18px"
            >
              {isConnected ? (
                <Link to={routes.candidateAccount.path}>Modifier mon profil</Link>
              ) : (
                'Déposer mon CV'
              )}
            </Button.Secondary>
          )}
        </Row>
      </Banner>
      <JobOffersHome />
      <CompanyPart
        setDefaultLoginEmail={setDefaultLoginEmail}
        handleClickButton={handleClickButton}
      />
      <CandidatPart
        setDefaultLoginEmail={setDefaultLoginEmail}
        handleClickButton={handleClickButton}
      />
    </Container>
  )
}

export default Home
