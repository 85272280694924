import React from 'react'
import { Helmet } from 'react-helmet'

import { H1, H2 } from 'styles/global'
import { Banner, Container } from './style'
import { ContactForm } from 'components'

const Contact = () => {
  return (
    <Container>
      <Helmet>
        <title>Emploi-dentiste | Service de chasse</title>
        <meta
          name="description"
          content="Prendre contact avec notre service de chasse afin que l'on trouve pour vous les profils dentiste dont vous avez besoin."
        />
        <meta name="keywords" content="service, recrutement, dentiste" />
      </Helmet>
      <Banner>
        <H1 margin="0 0 10px 0" style={{ maxWidth: '80%' }} size="40px" weight="500" color="white">
          Nous mettons notre
          <span style={{ fontWeight: 'bold', margin: '0 11px' }}>expertise</span>à votre
          <span style={{ fontWeight: 'bold', marginLeft: '11px' }}>service</span>
        </H1>
        <H2 style={{ maxWidth: '80%' }} size="36px" weight="400" color="white">
          Laissez nous un message et nous vous trouverons les meilleurs profils dentiste
        </H2>
      </Banner>
      <ContactForm />
    </Container>
  )
}

export default Contact
