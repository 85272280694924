import React from 'react'
import {Anchor} from 'antd'
import { AnchorBox } from './style'

type Item = {
  key: string
  href: string
  title: string
}

interface Props {
  items: Item[]
  affix?: boolean
}

const AnchorT = ({ items, affix }: Props) => {
  return <Anchor
  affix={affix || false}
  items={items}
/>
}

export default AnchorT
