import { InputButton } from 'components'
import React, { Dispatch, SetStateAction } from 'react'
import { Column, H2, Text } from 'styles/global'

import Img from 'assets/landings/company/img1.svg'
import { PartRow, Img1 } from '../style'
import { routes } from 'router'
import { Link } from 'react-router-dom'

const CompanyPart = ({
  handleClickButton,
  setDefaultLoginEmail,
}: {
  handleClickButton: (e: any, path: string) => void
  setDefaultLoginEmail: Dispatch<SetStateAction<string>>
}) => {
  return (
    <PartRow wrap justifyContent="space-between" blue>
      <Column responsive="width: 95%" width="50%">
        <H2 weight="500" size="22px">
        Emploi-Dentiste : Votre partenaire recrutement de dentistes
        </H2>
        <Text margin="15px 0 15px 0" weight="300">
          Inscrivez vous gratuitement afin d’accéder aux profils dentiste et publier vos offres
          d’emploi
        </Text>
        <InputButton
          callback={(value, e) => {
            setDefaultLoginEmail(value)
            handleClickButton(e, 'default')
          }}
          buttonContent="Publier une offre d'emploi"
          placeholder="Adresse email"
        />
      </Column>
      <Img1 src={Img} alt="recrutement dentiste" />
      <Text width="100%" margin="15px 0">
        Dans le monde extrêmement compétitif de la santé bucco-dentaire, le{' '}
        <Link
          style={{ fontWeight: '500', textDecoration: 'underline' }}
          to={routes.companyHome.path}
        >
          recrutement de dentistes hautement qualifiés{' '}
        </Link>{' '}
        s'est transformé en un enjeu absolument crucial. Chez emploi-dentiste, nous ne nous
        contentons pas de comprendre ces défis, mais nous les abordons de front. Notre plateforme,
        spécialement conçue pour les cabinets, recruteurs et professionnels en recherche d'emploi,
        offre des solutions adaptées aux besoins particuliers de{' '}
        <strong>recrutement dans le secteur dentaire</strong>. Elle sert d'interface entre les
        cabinets dentaires et les professionnels à la recherche d'opportunités pour faire avancer
        leur carrière.
        <br />
        <br /> Grâce à une large audience de dentistes, emploi-dentiste est plus qu'une simple
        plateforme de recrutement, c'est une communauté active de{' '}
        <strong>professionnels du secteur dentaire</strong>. Nous avons construit un espace où les
        candidats qualifiés et les employeurs peuvent se connecter facilement et efficacement. Nos
        visiteurs comprennent non seulement des dentistes expérimentés à la recherche de nouvelles
        opportunités, mais aussi de jeunes professionnels passionnés et prêts à faire leurs preuves.
        <br />
        <br />
        Nous proposons un ensemble de services de <strong>recrutement en dentisterie</strong>{' '}
        personnalisés et adaptés à vos besoins. En publiant vos annonces d’offres d’emploi sur
        emploi-dentiste.fr, vous êtes assurés d’être vus par un public spécialisé dans le secteur
        dentaire. Ce n'est pas simplement une question de quantité, mais de qualité. Nous nous
        efforçons de vous mettre en relation avec les bons candidats, ceux qui correspondent à vos
        critères et qui peuvent réellement contribuer à la croissance de votre cabinet.
        <br />
        <br /> Un autre avantage d'utiliser emploi-Dentiste.fr est l'accès à une base de CV de
        dentistes enrichie. Nous savons que le processus de recrutement ne se limite pas à la
        publication d'une annonce d'emploi. C'est pourquoi nous mettons à votre disposition une base
        de CV constamment mise à jour. Cet outil vous permet d'évaluer rapidement le parcours, les
        compétences et les qualifications des candidats potentiels, facilitant ainsi le processus de
        sélection.
        <br />
        <br /> Chez emploi-dentiste, nous comprenons que chaque entreprise est unique et a des
        besoins spécifiques. C'est pourquoi nous offrons un soutien personnalisé tout au long de
        votre processus de recrutement.
        <br />
        <br /> Nos experts sont à votre écoute pour comprendre vos attentes, vous conseiller et vous
        aider à optimiser votre stratégie de recrutement. Emploi-dentiste est plus qu'une simple
        plateforme de recrutement, c'est un partenaire stratégique pour l’
        <strong>embauche de dentistes qualifiés</strong>. En associant notre audience spécialisée,
        nos outils de recrutement innovants et notre soutien personnalisé, nous vous donnons toutes
        les cartes pour trouver le <strong>professionnel du secteur dentaire</strong> le plus
        pertinent pour votre cabinet.
      </Text>
    </PartRow>
  )
}

export default CompanyPart
