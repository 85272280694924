import React from 'react'
import { Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/company/img8.svg'
import { Img3, PartColumn } from '../style'

const Part13 = () => {
  return (
    <PartColumn blue id="title-12">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="65%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Une offre d’emploi optimisée
          </H3>
          <Text margin="15px 0">
            Nous vous aidons ensuite à optimiser votre offre d'emploi pour qu'elle se démarque des
            autres. Nous savons que dans le monde concurrentiel du <strong>recrutement dentaire</strong>,
            attirer l'attention des candidats qualifiés n'est pas une tâche facile. C'est pourquoi
            nous utilisons notre expertise et nos connaissances pour vous aider à rédiger des
            annonces attrayantes et pertinentes, capables d'attirer les candidats qui correspondent
            le mieux à vos critères. <br />
            <br /> Une fois votre offre publiée, nous continuons à vous accompagner tout au long du
            processus de sélection. Grâce à notre plateforme, vous pouvez facilement gérer vos
            candidatures, évaluer les profils des candidats et organiser des entretiens. De plus,
            nos experts sont toujours disponibles pour vous conseiller et vous aider à faire les
            meilleurs choix.
          </Text>
        </Column>
        <Img3 style={{ marginBottom: '20px' }} src={Img} alt="offre emploi optimisée" />
      </Row>
    </PartColumn>
  )
}

export default Part13
