import { styled, theme, mediaQuery } from 'styles'

export const ProfilePicture = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-right: 30px;
  object-fit: contain;
  ${mediaQuery.mobile} {
    margin-bottom: 30px;
  }
`

export const InformationsContent = styled.div`
  width: 100%;
  border: 1px solid ${theme.color.grey};
  border-radius: 6px;
  padding: 20px 30px;
  margin-top: 30px;
  min-width: 330px;
`
