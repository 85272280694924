export const cities = [
  `Paris`,
  `Marseille`,
  `Lyon`,
  `Toulouse`,
  `Nice`,
  `Nantes`,
  `Montpellier`,
  `Strasbourg`,
  `Bordeaux`,
  `Lille`,
  `Rennes`,
  `Reims`,
  `Toulon`,
  `Saint-Étienne`,
  `Le Havre`,
  `Dijon`,
  `Grenoble`,
  `Angers`,
  `Villeurbanne`,
  `Saint-Denis`,
  `Nîmes`,
  `Clermont-Ferrand`,
  `Aix-en-Provence`,
  `Le Mans`,
  `Brest`,
  `Tours`,
  `Amiens`,
  `AnnecyNote 3`,
  `Limoges`,
  `Boulogne-Billancourt`,
  `Metz`,
  `Besançon`,
  `Perpignan`,
  `Orléans`,
  `Rouen`,
  `Saint-Denis`,
  `Montreuil`,
  `Argenteuil`,
  `Mulhouse`,
  `Caen`,
  `Nancy`,
  `Saint-Paul`,
  `Tourcoing`,
  `Roubaix`,
  `Nanterre`,
  `Vitry-sur-Seine`,
  `Nouméa11`,
  `Créteil`,
  `Avignon`,
  `Poitiers`,
  `Aubervilliers`,
  `Asnières-sur-Seine`,
  `Colombes`,
  `DunkerqueNote 4`,
  `Aulnay-sous-Bois`,
  `Saint-Pierre`,
  `Versailles`,
  `Courbevoie`,
  `Le Tampon`,
  `Béziers`,
  `Rueil-Malmaison`,
  `Cherbourg-en-CotentinNote 5`,
  `Champigny-sur-Marne`,
  `La Rochelle`,
  `Pau`,
  `Fort-de-France`,
  `Antibes`,
  `Saint-Maur-des-Fossés`,
  `Mérignac`,
  `Ajaccio`,
  `Cannes`,
  `Saint-Nazaire`,
  `Mamoudzou`,
  `Drancy`,
  `Noisy-le-Grand`,
  `Colmar`,
  `Issy-les-Moulineaux`,
  `Cergy`,
  `Calais`,
  `Levallois-Perret`,
  `Vénissieux`,
  `Évry-CourcouronnesNote 6`,
  `Cayenne`,
  `Pessac`,
  `Valence`,
  `Bourges`,
  `Ivry-sur-Seine`,
  `Quimper`,
  `Clichy`,
  `Antony`,
  `Troyes`,
  `La Seyne-sur-Mer`,
  `Montauban`,
  `Villeneuve-d'Ascq`,
  `Pantin`,
  `Neuilly-sur-Seine`,
  `Chambéry`,
  `Niort`,
  `Sarcelles`,
  `Le Blanc-Mesnil`,
  `Maisons-Alfort`,
  `Lorient`,
  `Beauvais`,
  `Saint-André`,
  `Villejuif`,
  `Narbonne`,
  `Fréjus`,
  `Meaux`,
  `La Roche-sur-Yon`,
  `Bobigny`,
  `Hyères`,
  `Épinay-sur-Seine`,
  `Chelles`,
  `Cholet`,
  `Vannes`,
  `Saint-Louis`,
  `Bondy`,
  `Clamart`,
  `Saint-Quentin`,
  `Les Abymes`,
  `Corbeil-Essonnes`,
  `Cagnes-sur-Mer`,
  `Bayonne`,
  `Sevran`,
  `Vaulx-en-Velin`,
  `Sartrouville`,
  `Saint-Ouen-sur-Seine`,
  `Fontenay-sous-Bois`,
  `Arles`,
  `Massy`,
  `Gennevilliers`,
  `Vincennes`,
  `Laval`,
  `Suresnes`,
  `Saint-Laurent-du-Maroni`,
  `Albi`,
  `Saint-Herblain`,
  `Grasse`,
  `Martigues`,
  `Saint-Priest`,
  `Bastia`,
  `Montrouge`,
  `Aubagne`,
  `Saint-Malo`,
  `Les Sables-d'OlonneNote 7`,
  `Évreux`,
  `La Courneuve`,
  `Carcassonne`,
  `Blois`,
  `Charleville-Mézières`,
  `Choisy-le-Roi`,
  `Brive-la-Gaillarde`,
  `Meudon`,
  `Livry-Gargan`,
  `Belfort`,
  `Rosny-sous-Bois`,
  `Alfortville`,
  `Chalon-sur-Saône`,
  `Noisy-le-Sec`,
  `Salon-de-Provence`,
  `Sète`,
  `Istres`,
  `Saint-Germain-en-LayeNote 8`,
  `Talence`,
  `Châlons-en-Champagne`,
  `Saint-Brieuc`,
  `Puteaux`,
  `Mantes-la-Jolie`,
  `Caluire-et-Cuire`,
  `Châteauroux`,
  `Garges-lès-Gonesse`,
  `Rezé`,
  `Tarbes`,
  `Alès`,
  `Valenciennes`,
  `Bron`,
  `Castres`,
  `Arras`,
  `Le Cannet`,
  `Bagneux`,
  `Bourg-en-Bresse`,
  `Melun`,
  `Angoulême`,
  `Thionville`,
  `Wattrelos`,
  `Boulogne-sur-Mer`,
  `Compiègne`,
  `Anglet`,
  `Gap`,
  `Colomiers`,
  `Douai`,
  `Montélimar`,
  `Poissy`,
  `Le Lamentin`,
  `Gagny`,
  `Draguignan`,
  `Stains`,
  `Joué-lès-Tours`,
  `Marcq-en-Barœul`,
  `Bagnolet`,
  `Saint-Joseph`,
  `Villenave-d'Ornon`,
  `Chartres`,
  `Villepinte`,
  `Saint-Martin-d'Hères`,
  `Pontault-Combault`,
  `Annemasse`,
  `Franconville`,
  `Savigny-sur-Orge`,
  `Saint-Benoît`,
  `Échirolles`,
  `Thonon-les-Bains`,
  `Neuilly-sur-Marne`,
  `Tremblay-en-France`,
  `La Ciotat`,
  `Châtillon`,
  `Villefranche-sur-Saône`,
  `Creil`,
  `Conflans-Sainte-Honorine`,
  `Saint-Raphaël`,
  `Sainte-Geneviève-des-Bois`,
  `Dumbéa11`,
  `Athis-Mons`,
  `Haguenau`,
  `Six-Fours-les-Plages`,
  `Saint-Chamond`,
  `Meyzieu`,
  `Villeneuve-Saint-Georges`,
  `Palaiseau`,
  `Saint-Leu`,
  `Matoury`,
  `Vitrolles`,
  `Roanne`,
  `Mâcon`,
  `Châtenay-Malabry`,
  `Sainte-Marie`,
  `Auxerre`,
  `Schiltigheim`,
  `Montluçon`,
  `Le Perreux-sur-Marne`,
  `Nogent-sur-Marne`,
  `Les Mureaux`,
  `Trappes`,
  `La Possession`,
  `Houilles`,
  `Romans-sur-Isère`,
  `Le Port`,
  `Marignane`,
  `Lens`,
  `Saint-Martin`,
  `Saint-Médard-en-Jalles`,
  `Nevers`,
  `Agen`,
  `Koungou`,
  `Pontoise`,
  `Bezons`,
  `Montigny-le-Bretonneux`,
  `Épinal`,
  `Herblay-sur-Seine`,
  `L'Haÿ-les-Roses`,
  `Châtellerault`,
  `Cambrai`,
  `Vigneux-sur-Seine`,
  `Romainville`,
  `Pierrefitte-sur-Seine`,
  `Malakoff`,
  `Aix-les-Bains`,
  `Viry-Châtillon`,
  `Le Chesnay-RocquencourtNote 9`,
  `Goussainville`,
  `Plaisir`,
  `Rillieux-la-Pape`,
  `Thiais`,
  `Dreux`,
  `Menton`,
  `Villemomble`,
  `Bègles`,
  `Baie-Mahault`,
  `Cachan`,
  `Savigny-le-Temple`,
  `Saint-Laurent-du-Var`,
  `Liévin`,
]
