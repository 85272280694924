/* eslint-disable */

import React from "react";
import { Link } from "react-router-dom";


const SuggestionCard = ({ article }) => {
  return (
    <div className="suggestion-container">
      <h3 style={{ fontSize: "18px" }} className="title-bold start">
        {article.title}
      </h3>
      <p className="text-300">{article.description}</p>
      <Link style={{ fontSize: "16px" }} to={article.pathname} className="text-300 blue">
      Lire l'article
      </Link>
    </div>
  );
};

export default SuggestionCard;
