import React from 'react'
import { Button, Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/candidat/img5.svg'
import { Img3, PartColumn } from '../style'

const Part5 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-4">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Déposez votre CV en ligne
          </H3>
          <Text margin="15px 0">
            En plus de la consultation d’offres d'emploi, notre plateforme donne la possibilité de
            déposer votre CV en ligne, ce qui permet aux recruteurs de vous contacter directement.
            Un système d'alerte emploi est également disponible et vous informe par email dès qu'une
            nouvelle offre correspondant à vos critères de recherche est publiée.
          </Text>
          <Button.Primary onClick={(e) => handleClickButton(e, 'default')}>
            Partager mon CV
          </Button.Primary>
        </Column>
        <Img3 src={Img} alt="déposer CV" />
      </Row>
    </PartColumn>
  )
}

export default Part5
