import React from 'react'
import { Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/company/img9.svg'
import { Img3, PartColumn } from '../style'

const Part15 = () => {
  return (
    <PartColumn id="title-14">
      <Row wrap width="100%" justifyContent="space-between">
        <Img3 src={Img} alt="plateforme recrutement" />
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Emploi-dentiste : Une plateforme de recrutement dentiste fiable et efficace
          </H3>
          <Text margin="15px 0">
            En tant que <strong>professionnel du domaine dentaire</strong>, il est essentiel pour
            vous de trouver une plateforme de recrutement qui soit non seulement efficace, mais
            également digne de confiance. Spécifiquement dédiée au secteur dentaire, notre
            plateforme a été conçue pour répondre aux besoins spécifiques des cabinets dentaires.
          </Text>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part15
