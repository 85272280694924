import React from 'react'
import { H3, Text } from 'styles/global'

import { PartColumn } from '../style'

const Part19 = () => {
  return (
    <PartColumn id="title-18">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Tenir à jour votre CV
      </H3>
      <Text margin="15px 0">
        Il est important de tenir à jour votre CV. Si vous acquérez de nouvelles compétences,
        obtenez une nouvelle certification ou changez de poste, assurez-vous de mettre à jour votre
        CV en conséquence. Un CV à jour donne une image professionnelle et montre aux recruteurs que
        vous êtes actif dans votre recherche d'emploi.
      </Text>
    </PartColumn>
  )
}

export default Part19
