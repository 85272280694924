import React from 'react'
import { Button, Column, Text } from 'styles/global'

import emptyImg from 'assets/illustrations/empty.png'
import { Link } from 'react-router-dom'

interface Props {
  link?: string
  messageLink?: string
}

const EmptyTableMessage = ({ link, messageLink }: Props) => {
  return (
    <Column width="100%" alignItems="center">
      <img src={emptyImg} alt="empty" style={{ height: '60px', marginBottom: '15px' }} />
      <Text size="16px" weight="300" color="greyBlack">
        Aucune donnée
      </Text>
      {link && (
        <Link style={{ marginTop: '30px' }} to={link}>
          <Button.Third>{messageLink}</Button.Third>
        </Link>
      )}
    </Column>
  )
}

export default EmptyTableMessage
