import React, { Dispatch, SetStateAction } from 'react'
import { theme } from 'styles'
import { Column, H3, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/candidat/img3.svg'
import { Img3, PartColumn } from '../style'
import { Link } from 'react-router-dom'
import { routes } from 'router'

const Part3 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-2">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Spécialisation dans le domaine dentaire
      </H3>
      <Row wrap justifyContent="space-between">
        <Column responsive="width: 100%;" width="60%">
          <Text margin="15px 0">
            Emploi-dentiste se distingue par sa spécialisation exclusive dans le domaine dentaire.
            Nous comprenons les spécificités de ce secteur, les compétences requises et les défis
            auxquels sont confrontés les professionnels de la santé bucco-dentaire. Que vous soyez
            un dentiste expérimenté à la recherche d'un nouveau défi, un jeune diplômé prêt à faire
            ses premiers pas dans le monde professionnel ou une assistante dentaire qualifiée
            cherchant à élargir ses horizons, nous vous aidons à trouver l'emploi qui correspond à
            vos aspirations.
          </Text>
          <Button.Primary onClick={(e) => handleClickButton(e, routes.viewOffers.path)}>
            Consulter les offres d’emploi
          </Button.Primary>
        </Column>
        <Img3 src={Img} alt="domaine dentaire" />
      </Row>
    </PartColumn>
  )
}

export default Part3
