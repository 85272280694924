import React, { ReactElement } from 'react'
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom'

// IMPORTATION DE PAGE
import PageWrapper, {
  Home,
  CreateEditJobOffer,
  JobOfferList,
  BlogHome,
  CGV,
  CGU,
  CandidateAccount,
  CompanyAccount,
  CandidateRender,
  MyOffers,
  CompanyProfile,
  JobOffer,
  AdminDashboard,
  Pricing,
  Contact,
  Article1,
  CompanyHome,
  CandidatHome,
  MyApplications,
  ApplicationList
} from 'pages'

import routes from 'router/routes'
import CandidateList from 'pages/cvTheque'

function Router(): ReactElement {
  return (
      <BrowserRouter>
        <PageWrapper>
          <Routes>
            {/* PUBLIC PAGES */}
            <Route path={routes.home.path} element={<Home />} />
            <Route path={routes.viewOffers.path} element={<JobOfferList />} />
            <Route path={routes.candidateList.path} element={<CandidateList />} />
            <Route path={routes.candidateRender.path} element={<CandidateRender />} />
            <Route path={routes.companyProfile.path} element={<CompanyProfile />} />
            <Route path={routes.jobOffer.path} element={<JobOffer />} />
            <Route path={routes.blogHome.path} element={<BlogHome />} />
            <Route path={routes.CGU.path} element={<CGU />} />
            <Route path={routes.CGV.path} element={<CGV />} />
            <Route path={routes.pricing.path} element={<Pricing />} />
            <Route path={routes.contact.path} element={<Contact />} />

            {/* CANDIDATE PAGES */}
            <Route path={routes.candidateAccount.path} element={<CandidateAccount />} />
          <Route path={routes.myApplications.path} element={<MyApplications />} />

            {/* ADMIN PAGES */}
            <Route path={routes.adminDashboard.path} element={<AdminDashboard />} />

            {/* COMPANY PAGES */}
            <Route path={routes.createOffer.path} element={<CreateEditJobOffer />} />
            <Route path={routes.editOffer.path} element={<CreateEditJobOffer />} />
            <Route path={routes.companyAccount.path} element={<CompanyAccount />} />
            <Route path={routes.myOffers.path} element={<MyOffers />} />
          <Route path={routes.applicationList.path} element={<ApplicationList />} />

            {/* BLOG PAGES */}
            <Route path={routes.article1.path} element={<Article1 />} />

            {/* HOME PAGES */}
            <Route path={routes.companyHome.path} element={<CompanyHome />} />
            <Route path={routes.candidatHome.path} element={<CandidatHome />} />

            <Route path="" element={<Home />} />
          </Routes>
        </PageWrapper>
      </BrowserRouter>
  )
}

export { routes }
export default Router
