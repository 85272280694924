import { styled, theme, mediaQuery } from 'styles'
import { Text } from 'styles/global'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 15px 25px 40px 25px;
  border: #dedede 1px solid;
  border-radius: 7px;
  background-color: white;
  position: relative;
  flex-wrap: wrap;
  position: fixed;
  left: 5vw;
  top: 100px;
  ${mediaQuery.mobile} {
    width: 95vw;
    max-width: 600px;
    position: relative;
    margin-bottom: 30px;
    left: auto;
    top: auto;
  }
`
export const AbsolutText = styled(Text)`
  margin-left: -10px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
  ${mediaQuery.mobile} {
    margin-left: 0;
  }
`
