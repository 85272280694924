import React, { Dispatch, SetStateAction } from 'react'
import { Column, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/company/img2.svg'
import { Img2, PartColumn } from '../style'

const Part2 = ({
  handleClickButton,
}: {
  handleClickButton: (e: any, path: string) => void
}) => {
  return (
    <PartColumn id="title-1">
    <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
      Recrutement dentistes et assistantes dentaires
    </H2>
      <Row wrap justifyContent="space-between">
        <Img2 src={Img} alt="recrutement dentaire" />
        <Column responsive="width: 100%;" width="60%">
          <Text margin="15px 0">
            Naviguer dans le domaine complexe du <strong>recrutement dentaire</strong> peut sembler une tâche
            intimidante. Que vous soyez un cabinet dentaire cherchant à renforcer votre équipe ou
            une entreprise du secteur dentaire en quête de talents spécialisés, trouver les
            candidats adéquats peut être un défi. C'est ici qu'intervient emploi-dentiste, votre
            partenaire dédié dans le recrutement dentaire. Avec une compréhension approfondie du
            marché et un engagement à fournir une solution sur mesure à vos besoins spécifiques,
            nous sommes équipés pour transformer votre processus de recrutement en une expérience
            plus fluide et plus efficace.
          </Text>
          <Button.Primary onClick={(e) => handleClickButton(e, "default")}>
            Publier une offre d’emploi
          </Button.Primary>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part2
