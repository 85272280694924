export const anchorItems = [
  {
    key: '1',
    href: '#title-1',
    title: 'Recrutement dentistes et assistantes dentaires',
  },
  {
    key: '2',
    href: '#title-5',
    title: 'Ressources pour optimiser le recrutement',
  },
  {
    key: '3',
    href: '#title-7',
    title: 'Outils pour faciliter votre recrutement',
  },
  {
    key: '4',
    href: '#title-11',
    title: 'Un partenaire qui s’engage à vos côtés',
  },
  {
    key: '5',
    href: '#title-13',
    title: 'Trouvez le professionnel dentaire dont vous avez besoin',
  },
  {
    key: '6',
    href: '#title-16',
    title: 'Une approche centrée sur la qualité',
  },
]