/* eslint-disable */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Tooltip } from "antd";
import {Row} from 'styles/global'

import linkImg from "assets/blog/global/link.svg";
import facebookImg from "assets/blog/global/facebook.svg";
import linkedinImg from "assets/blog/global/linkedin.svg";
import whatsappImg from "assets/blog/global/whatsapp.svg";
import validCopyImg from "assets/blog/global/valid_copy.svg";

const Social = ({ article }) => {
  const [isCopied, setIsCopied] = useState(false);

  const navigate = useNavigate()

  function copyLink() {
    navigator.clipboard.writeText(window.location.href);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }

  return (
    <div style={{ height: "30px", marginTop: "30px", width: "100%" }}>
      <Row wrap width="100%" style={{ justifyContent: "space-between" }} className="row-wrap width-100">
        <Row className="row">
          {article.tags.map((tag) => (
            <p onClick={(e) => navigate(`/blog?tag=${tag}`)} className="blog-tag">
              {tag}
            </p>
          ))}
        </Row>
        <div style={{ margin: "5px" }} className="row">
          <Tooltip title="Copied!" open={isCopied}>
            <img
              className="pointer"
              alt="copy article url"
              onClick={copyLink}
              style={{ width: "35px", marginRight: "10px" }}
              src={isCopied ? validCopyImg : linkImg}
            />
          </Tooltip>

          <a
            className="pointer"
            style={{ marginRight: "10px" }}
            rel="noopener noreferrer"
            target="_blank"
            href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
          >
            <img style={{ width: "30px" }} src={facebookImg} alt="facebook share" />
          </a>
          <a
            className="pointer"
            style={{ marginRight: "10px" }}
            rel="noopener noreferrer"
            target="_blank"
            href={`http://www.linkedin.com/shareArticle?url=${window.location.href}`}
          >
            <img style={{ width: "30px" }} src={linkedinImg} alt="linkedin share" />
          </a>
          <a
            className="pointer"
            style={{ marginRight: "10px" }}
            rel="noopener noreferrer"
            target="_blank"
            href={`whatsapp://send?text=${window.location.href}`}
            data-action="share/whatsapp/share"
          >
            <img style={{ width: "30px" }} src={whatsappImg} alt="whatsapp share" />
          </a>
        </div>
      </Row>
      <Divider style={{ marginTop: "10px" }} />
    </div>
  );
};

export default Social;
