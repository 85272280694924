import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Modal } from 'antd'

import { Illustration } from './style'
import { Text, Button, Column, Title } from 'styles/global'
import { routes } from 'router'
import { authUtils } from 'utils'

import badPlanImage from 'assets/illustrations/access_denied.svg'
import Login from 'components/login'
import { ACCOUNT_TYPES } from 'constants/index'

const NeedConnexion = () => {
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')

  return (
    <Modal visible={true} onCancel={() => null} footer={[]} closable={false}>
      {isLoginFormDisplayed && (
        <Login isDisplayed={isLoginFormDisplayed} setIsDisplayed={setIsLoginFormDisplayed} />
      )}
      <Column alignItems="center" width="100%">
        <Column alignItems="center" width="80%">
          <Title>Création d'une offre d'emploi</Title>
          <Text position="center" size="18px">
            Vous devez être connecté sur un compte entreprise afin d'accéder à ce service
          </Text>
          <Illustration alt="mauvais plan illustration" src={badPlanImage} />

          {authUtils.currentRole() === ACCOUNT_TYPES.CANDIDATE ? (
            <Text margin="13px 0" size="18px">
              Il semble que vous soyez sur un compte candidat, si vous souhaitez poster une annonce,
              veuillez
              <Link to={routes.candidateAccount.path}>vous déconnecter</Link> et vous connecter à un
              compte entreprise
            </Text>
          ) : (
            <Button.Primary onClick={() => setIsLoginFormDisplayed(window.location.pathname)}>
              Se connecter
            </Button.Primary>
          )}
        </Column>
      </Column>
    </Modal>
  )
}

export default NeedConnexion
