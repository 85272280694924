import React from 'react'
import { Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/company/img9.svg'
import { Img3, PartColumn } from '../style'

const Part16 = () => {
  return (
    <PartColumn id="title-15">
      <Row wrap width="100%" justifyContent="space-between">
        <Img3 src={Img} alt="paccompagne recherche emploi" />
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Accompagnement tout au long de votre recherche d'emploi
          </H3>
          <Text margin="15px 0">
            En plus de la mise en relation entre candidats et recruteurs, nous nous efforçons de
            fournir des ressources utiles pour aider les candidats dans leur recherche d'emploi. Que
            ce soit des conseils pour la rédaction de votre CV, des astuces pour réussir votre
            entretien d'embauche ou des informations sur les tendances du marché de l'emploi dans le
            secteur dentaire, nous sommes là pour vous accompagner à chaque étape de votre parcours
            professionnel. En somme, notre plateforme est conçue pour faciliter votre recherche
            d'emploi dentiste ou d'emploi assistante dentaire. Nous vous invitons à découvrir nos
            services et à commencer dès aujourd'hui votre recherche d'emploi.
          </Text>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part16
