import React from 'react'
import { H2, Text } from 'styles/global'

import { PartColumn } from '../style'
import { routes } from 'router'
import { Link } from 'react-router-dom'
import { theme } from 'styles'

const Part27 = () => {
  return (
    <PartColumn id="title-26">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Votre partenaire pour l'emploi dentiste
      </H2>
      <Text margin="15px 0">
        Que vous soyez à la recherche d'un emploi dentiste ou d'un emploi assistante dentaire, notre
        plateforme se positionne comme l'outil idéal pour vous accompagner dans votre recherche.
        Nous sommes plus qu'un simple site d'offres d'emploi, nous sommes un véritable partenaire
        dans votre parcours professionnel.
        <br />
        <br /> Nous vous offrons un accès à un large éventail d'offres d'emploi, soigneusement
        sélectionnées et régulièrement mises à jour, couvrant toutes les régions de France.
        Emploi-dentiste est conçu pour être facile à utiliser, avec une interface intuitive et des
        fonctionnalités qui facilitent votre recherche.
        <br />
        <br /> Outre la possibilité de consulter diverses opportunités professionnelles, nous vous
        donnons l'option de soumettre votre CV en ligne. Par ailleurs, nous avons instauré un
        système d'alerte d'emploi qui, dès qu'une offre correspondant à vos critères est mise en
        ligne, vous en informe par courriel. Nous vous invitons à découvrir toutes les offres
        d'emploi et à déposer votre CV en ligne dès aujourd'hui. Nous sommes impatients de vous
        aider à trouver le travail qui vous convient dans le secteur dentaire.
        <br />
        <br /> Vous êtes un cabinet ou un recruteur ?{' '}
        <Link
          style={{ color: theme.color.black, textDecoration: 'underline' }}
          to={routes.companyHome.path}
        >
          Découvrez notre page spécialement dédiée au recrutement de dentiste
        </Link>{' '}
        Pour plus d’informations concernant le recrutement dans le secteur dentaire, la rédaction de
        CV optimisés ou les tendances du marché de l’emploi, n’hésitez pas à{' '}
        <a
          style={{ color: theme.color.black, textDecoration: 'underline' }}
          rel="noreferrer"
          href="https://blog.emploi-dentiste.fr"
          target="_blank"
        >
          consulter notre Blog{' '}
        </a>
        spécialisé
      </Text>
    </PartColumn>
  )
}

export default Part27
