import React, { Dispatch, SetStateAction, useContext, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Drawer, Dropdown, Menu as AntdMenu } from 'antd'

import { UserContext } from 'providers'
import { routes } from 'router'
import { HeaderContainer, MenuItem, Menu, BurgerMenuContainer, BurgerMenuIcon, Logo } from './style'
import { Row, Button, Text } from 'styles/global'
import { authUtils, urlParams } from 'utils'
import Login from 'components/login'

import { Link, useLocation } from 'react-router-dom'
import { ACCOUNT_TYPES } from 'constants/index'
import { useAuth } from 'hooks'
import { theme } from 'styles'

import burgerMenuImg from 'assets/icons/menu.png'
import logo from 'assets/illustrations/logo.png'
import MyProfil from './MyProfil'

const linkStyle = {
  fontSize: '15px',
  height: '25px',
  marginTop: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

type Props = {
  isMobile: boolean
  setIsMobileMenuDisplayed: Dispatch<SetStateAction<boolean>>
}

const Header = ({ isMobile, setIsMobileMenuDisplayed }: Props) => {
  const { t } = useTranslation()
  const { user, resetUser } = useContext(UserContext)
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [currentPage, setCurrentPage] = useState('')
  const [isFixedNavbar, setIsFixednavbar] = useState(false)
  const [isLoginFormDisplayed, setIsLoginFormDisplayed] = useState<string>('')

  const { logout } = useAuth()
  const location = useLocation()

  const isAuth = authUtils.isAuth()

  const paramsUrl = urlParams()

  // CHEK IF WE RESET PASSWORD
  useEffect(() => {
    if (paramsUrl.resetCode) {
      setIsLoginFormDisplayed('/')
    }
  }, [])

  window.onscroll = function (e) {
    if (!isFixedNavbar && window.scrollY > 95) {
      setIsFixednavbar(true)
      const body = document.getElementById('body-wrapper')
      if (body) {
        body.style.paddingTop = '130px'
      }
    } else if (window.scrollY === 0) {
      setIsFixednavbar(false)
      const body = document.getElementById('body-wrapper')
      if (body) {
        body.style.paddingTop = '0'
      }
    }
  }

  useEffect(() => {
    setIsMobileMenuOpen(false)
    if (location.pathname === routes.home.path) {
      setCurrentPage('1')
    } else if (location.pathname === routes.viewOffers.path) {
      setCurrentPage('2')
    } else if (location.pathname === routes.createOffer.path) {
      setCurrentPage('3')
    }
  }, [location])

  const checkNeedConnexion = (e, redirect) => {
    if (!authUtils.isAuth()) {
      e.stopPropagation()
      e.preventDefault()
      setIsLoginFormDisplayed(redirect)
    }
  }

  const dropDown = (
    <AntdMenu
      items={[
        {
          key: '1',
          label: (
            <Link
              onClick={(e) => checkNeedConnexion(e, routes.createOffer.path)}
              style={{ marginRight: '50px' }}
              to={routes.createOffer.path}
            >
              Déposer une offre d&#39;emploi
            </Link>
          ),
        },
        {
          key: '2',
          label: (
            <Link style={{ marginRight: '50px' }} to={routes.candidateList.path}>
              CvThèque
            </Link>
          ),
        },
        {
          key: '3',
          label: <Link to={routes.pricing.path}>Tarifs</Link>,
        },
        /*
        {
          key: '4',
          label: <Link to={routes.contact.path}>Service de chasse</Link>,
        },*/
      ]}
    />
  )

  return (
    <>
      {isLoginFormDisplayed && (
        <Login
          isRegistration={true}
          isDisplayed={isLoginFormDisplayed}
          setIsDisplayed={setIsLoginFormDisplayed}
        />
      )}
      {isMobile ? (
        <>
          <HeaderContainer
            style={
              isFixedNavbar
                ? {
                    position: 'fixed',
                    top: '0',
                    zIndex: '100',
                    width: '100vw',
                  }
                : {}
            }
          >
            <BurgerMenuContainer onClick={() => setIsMobileMenuOpen(true)}>
              <BurgerMenuIcon alt="menu burger icon" src={burgerMenuImg} />
            </BurgerMenuContainer>
            <Logo src={logo} alt="emploi-pharmacien.fr logo" />
          </HeaderContainer>
          <Drawer
            className="blue-back"
            title="emploi-pharmacien.fr"
            placement="left"
            onClose={() => setIsMobileMenuOpen(false)}
            visible={isMobileMenuOpen}
          >
            {!isAuth && (
              <MenuItem
                style={{ marginBottom: '15px' }}
                isActive={window.location.pathname === routes.home.path}
              >
                <Link to={routes.home.path}>Accueil</Link>
              </MenuItem>
            )}

            {/* POUR CABINETS */}
            {(authUtils.currentRole() === ACCOUNT_TYPES.COMPANY || !isAuth) && (
              <>
                <MenuItem
                  style={{ marginBottom: '15px' }}
                  isActive={window.location.pathname === routes.createOffer.path}
                >
                  <Link
                    onClick={(e) => checkNeedConnexion(e, routes.createOffer.path)}
                    to={routes.createOffer.path}
                  >
                    Déposer une offre d&#39;emploi
                  </Link>
                </MenuItem>
              </>
            )}

            {authUtils.currentRole() === ACCOUNT_TYPES.CANDIDATE || !isAuth ? (
              <>
                <MenuItem
                  style={{ marginBottom: '15px' }}
                  isActive={window.location.pathname === routes.viewOffers.path}
                >
                  <Link to={routes.viewOffers.path}>Offres d’emplois</Link>
                </MenuItem>
                {isAuth && (
                  <MenuItem
                    style={{ marginBottom: '15px' }}
                    isActive={window.location.pathname === routes.myApplications.path}
                  >
                    <Link to={routes.myApplications.path}>Mes candidatures</Link>
                  </MenuItem>
                )}
                {isAuth ? (
                  <MenuItem
                    style={{ marginBottom: '15px' }}
                    isActive={window.location.pathname === routes.candidateAccount.path}
                  >
                    <Link to={routes.candidateAccount.path}>Modifier mon profil</Link>
                  </MenuItem>
                ) : (
                  <MenuItem
                    style={{ marginBottom: '15px' }}
                    isActive={window.location.pathname === routes.candidateAccount.path}
                  >
                    <Text size="16px" onClick={() => setIsLoginFormDisplayed(routes.candidateAccount.path)}>
                      Déposer mon CV
                    </Text>
                  </MenuItem>
                )}
              </>
            ) : (
              authUtils.currentRole() === ACCOUNT_TYPES.COMPANY && (
                <>
                  <MenuItem
                    style={{ marginBottom: '15px' }}
                    isActive={window.location.pathname === routes.myOffers.path}
                  >
                    <Link to={routes.myOffers.path}>Mes annonces</Link>
                  </MenuItem>
                  <MenuItem
                    style={{ marginBottom: '15px' }}
                    isActive={window.location.pathname === routes.companyAccount.path}
                  >
                    <Link to={routes.companyAccount.path}>Mon compte</Link>
                  </MenuItem>
                </>
              )
            )}
            {!isAuth ? (
              <Button.Third
                onClick={() => setIsLoginFormDisplayed('default')}
                style={{ height: '45px', width: '150px', marginTop: '12px' }}
              >
                Connexion
              </Button.Third>
            ) : (
              <Button.Third
                onClick={logout}
                style={{ height: '45px', width: '150px', position: 'absolute', bottom: '8vh' }}
              >
                Déconnexion
              </Button.Third>
            )}
          </Drawer>
        </>
      ) : (
        <Menu
          style={
            isFixedNavbar
              ? {
                  position: 'fixed',
                  top: '0',
                  zIndex: '100',
                  width: '100vw',
                }
              : {}
          }
          key={currentPage}
          selectedKeys={[currentPage]}
          mode="horizontal"
          className="header"
        >
          {isAuth && (
            <Link to={routes.home.path}>
              <Logo src={logo} alt="logo" />
            </Link>
          )}
          {!isAuth && (
            <>
              <MenuItem
                style={{ marginRight: '40px' }}
                isActive={window.location.pathname === routes.home.path}
              >
                <Link to={routes.home.path}>Accueil</Link>
              </MenuItem>
              <Dropdown overlay={dropDown}>
                <a style={{ fontSize: '16px' }} onClick={(e) => e.preventDefault()}>
                  Services aux entreprises
                </a>
              </Dropdown>
            </>
          )}
          {authUtils.currentRole() === ACCOUNT_TYPES.CANDIDATE || !isAuth ? (
            <Row key="5" style={{ position: 'absolute', right: '14vw' }}>
              {!isAuth ? (
                <Link to={routes.viewOffers.path}>
                  <Button.Primary style={{ height: '40px', width: '185px', marginRight: '12px' }}>
                    Offres d’emplois
                  </Button.Primary>
                </Link>
              ) : (
                <MenuItem isActive={window.location.pathname === routes.viewOffers.path}>
                  <Link style={linkStyle} to={routes.viewOffers.path}>
                    Offres d’emplois
                  </Link>
                </MenuItem>
              )}
              <MyProfil />
              {!isAuth && (
                <>
                  <Button.Primary
                    onClick={() => setIsLoginFormDisplayed(routes.candidateAccount.path)}
                    style={{ height: '40px', width: '190px', marginLeft: '12px' }}
                  >
                    Déposer mon CV
                  </Button.Primary>
                  <Button.Third
                    onClick={() => setIsLoginFormDisplayed('default')}
                    style={{ height: '40px', width: '130px', marginLeft: '24px' }}
                  >
                    Connexion
                  </Button.Third>
                </>
              )}
            </Row>
          ) : (
            authUtils.currentRole() === ACCOUNT_TYPES.COMPANY && (
              <>
                <Row key="5" style={{ position: 'absolute', right: '14vw' }}>
                  <MenuItem
                    style={{ marginRight: '20px' }}
                    isActive={window.location.pathname === routes.myOffers.path}
                  >
                    <Link style={linkStyle} to={routes.myOffers.path}>
                      Mes annonces
                    </Link>
                  </MenuItem>
                  <MyProfil />
                </Row>
              </>
            )
          )}
        </Menu>
      )}
    </>
  )
}

export default Header
