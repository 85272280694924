import React from 'react'
import { Button, H3, Row, Text } from 'styles/global'

import { PartColumn } from '../style'
import { routes } from 'router'
import { Link } from 'react-router-dom'

const Part20 = () => {
  return (
    <PartColumn id="title-19">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Consulter régulièrement les nouvelles offres d'emploi
      </H3>
      <Text margin="15px 0">
        Nous mettons régulièrement à jour notre plateforme avec de nouvelles{' '}
        <strong>offres emploi dentiste</strong>
        et <strong>offres emploi assistante dentaire</strong>. Nous vous conseillons de consulter
        régulièrement ces nouvelles offres pour ne pas manquer une opportunité qui pourrait
        correspondre à vos critères.
      </Text>
      <Row margin="25px 0 0 0" justifyContent="center" width="100%">
        <Link to={routes.viewOffers.path}>
          <Button.Primary width="350px" minHeight="45px">
            Consulter les dernières offres d’emploi
          </Button.Primary>
        </Link>
      </Row>
    </PartColumn>
  )
}

export default Part20
