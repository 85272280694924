import { styled } from 'styles'
import { Column } from 'styles/global'

export const Container = styled(Column)`
  width: 100vw;
  min-height: 100vh;
  align-items: center;
`

export const ContentContainer = styled(Column)`
  background: white;
  border-radius: 8px;
  padding: 15px;
`
