import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { Column } from 'styles/global'
import { PartRow } from './style'
import { Anchor } from 'components'
import { anchorItems } from './anchorItems'
import {
  Part1,
  Part2,
  Part3,
  Part4,
  Part5,
  Part6,
  Part7,
  Part8,
  Part9,
  Part10,
  Part11,
  Part12,
  Part13,
  Part14,
  Part15,
  Part16,
  Part17,
  Part18,
  Part19,
} from './components'
import Login from 'components/login'
import { authUtils } from 'utils'
import { useNavigate } from 'react-router-dom'
import { routes } from 'router'

const CompanyHome = () => {
  const [loginRedirection, setLoginRedirection] = useState<string>('')
  const [defaultLoginEmail, setDefaultLoginEmail] = useState<string>('')
  const navigate = useNavigate()

  const handleClickButton = (e, path) => {
    if (authUtils.isAuth() || path === routes.contact.path) {
      navigate(path === 'default' ? routes.myOffers.path : path)
    } else {
      e.preventDefault()
      e.stopPropagation()
      setLoginRedirection(path)
    }
  }

  return (
    <Column style={{ background: 'white' }} width="100%" alignItems="center">
      <Helmet>
        <title>Recrutement dentiste : votre partenaire en recrutement dentaire</title>
        <meta
          name="description"
          content="Emploi-dentiste.fr est le partenaire de vos recrutements dans le domaine dentaire. Nous proposons des solutions sur mesure pour cabinets et entreprises du secteur dentaire. Trouvez le dentiste ou l'assistante dentaire idéal grâce à nos services personnalisés."
        />
        <meta name="keywords" content="dentiste, emploi, recrutement" />
      </Helmet>
      {loginRedirection && (
        <Login
          defaultEmail={defaultLoginEmail}
          isDisplayed={loginRedirection}
          setIsDisplayed={setLoginRedirection}
        />
      )}
      <Part1 handleClickButton={handleClickButton} setDefaultLoginEmail={setDefaultLoginEmail} />
      <PartRow>
        <Anchor items={anchorItems} />
      </PartRow>
      <Part2 handleClickButton={handleClickButton} />
      <Part3 handleClickButton={handleClickButton} />
      <Part4 handleClickButton={handleClickButton} />
      <Part5 />
      <Part6 handleClickButton={handleClickButton} />
      <Part7 />
      <Part8 handleClickButton={handleClickButton} />
      <Part9 handleClickButton={handleClickButton} />
      <Part10 />
      <Part11 handleClickButton={handleClickButton} />
      <Part12 />
      <Part13 />
      <Part14 handleClickButton={handleClickButton} />
      <Part15 />
      <Part16 />
      <Part17 handleClickButton={handleClickButton} />
      <Part18 />
      <Part19 />
    </Column>
  )
}

export default CompanyHome
