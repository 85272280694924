import React from 'react'
import { H3, Text } from 'styles/global'

import { PartColumn } from '../style'

const Part10 = () => {
  return (
    <PartColumn width="100%" id="title-9">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Une approche unique pour les cabinets dentaires
      </H3>
      <Text margin="15px 0">
        Mais notre soutien ne s'arrête pas là. Nous comprenons que chaque cabinet a ses propres
        besoins et qu'une approche standardisée du recrutement peut ne pas être la plus efficace.
        C'est pourquoi nous mettons à votre disposition des services personnalisés, visant à vous
        accompagner tout au long de votre <strong>recherche de dentiste</strong>.<br /> Qu'il s'agisse de
        conseils pour améliorer la visibilité de vos annonces, de stratégies pour atteindre un
        public plus large ou encore d'aide pour évaluer et sélectionner les candidats, nous sommes
        là pour vous aider.
        <br />
        <br />
        En ce sens, emploi-dentiste n'est pas seulement une plateforme de recrutement. C'est un
        partenaire fiable, un allié qui met tout en œuvre pour vous aider à trouver la perle rare.
      </Text>
    </PartColumn>
  )
}

export default Part10
