import { InputButton } from 'components'
import React, { Dispatch, SetStateAction } from 'react'
import { theme } from 'styles'
import { Column, H1, Row, Text } from 'styles/global'

import Img from 'assets/landings/candidat/img1.svg'
import { PartRow, Img1 } from '../style'

const Part1 = ({
  handleClickButton,
  setDefaultLoginEmail,
}: {
  handleClickButton: (e: any, path: string) => void
  setDefaultLoginEmail: Dispatch<SetStateAction<string>>
}) => {
  return (
    <PartRow wrap justifyContent="space-between" blue>
      <Column responsive="width: 95%" width="50%">
        <H1 weight="500" size="22px">
          Recherche emploi dentiste
        </H1>
        <Text margin="15px 0">
          Que vous soyez dentiste ou assistante dentaire, nous vous aidons à vous connecter avec les
          cabinets et recruteurs à la recherche de talents. Découvrez un outil intuitif et
          personnalisé, dédié à votre réussite professionnelle.
        </Text>
        <InputButton
          callback={(value, e) => {
            setDefaultLoginEmail(value)
            handleClickButton(e, 'default')
          }}
          buttonContent="S’inscrire"
          placeholder="Adresse email"
        />
        <Text margin="15px 0 0 0" weight="300">
          Inscrivez vous gratuitement afin d’accéder aux offres d’emploi et partager votre profil aux
          recruteurs
        </Text>
      </Column>
      <Img1 src={Img} alt="Recherche emploi dentiste" />
    </PartRow>
  )
}

export default Part1
