/* eslint-disable */

import React from "react";
import "./style.css";

const Summary = ({ list }) => {
  return (
    <div className="column course-sponso-container summary">
      <p style={{ marginBottom: "10px" }} className="title-bold montserrat">
        Summary
      </p>
      {list}
    </div>
  );
};

export default Summary;
