import React, { Dispatch, SetStateAction } from 'react'
import { H3, Row, Text, Button } from 'styles/global'

import { PartColumn } from '../style'
import { routes } from 'router'

const Part15 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-14">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Mise en relation avec les recruteurs
      </H3>
      <Text margin="15px 0">
        Une fois que votre CV est en ligne, les recruteurs peuvent vous contacter directement. C'est
        un moyen efficace de vous faire connaître des employeurs potentiels et d'augmenter vos
        chances de trouver l'emploi de vos rêves. De plus, nous avons mis en place un système
        d'alerte emploi qui vous informe par email dès qu'une nouvelle offre correspondant à vos
        critères de recherche est publiée.
      </Text>
      <Row margin="25px 0 0 0" justifyContent="center" width="100%">
        <Button.Primary
          width="350px"
          minHeight="45px"
          onClick={(e) => handleClickButton(e, 'default')}
        >
          M’inscrire et partager mon profil
        </Button.Primary>
      </Row>
    </PartColumn>
  )
}

export default Part15
