/* eslint-disable */

import React from 'react'
import { Anchor } from 'antd'

import Summary from '../../../../components/blog/Summary'

import coverImg from 'assets/blog/global/cover.svg'

import { H1, H2, H3, H4, Text } from 'styles/global'

const Content = () => {
  return (
    <div className="column width-100">
      <H1 size="22px" style={{ marginTop: '20px' }}>
        This is a long title to showcase a long example for articles.
      </H1>
      <Text>3 december 2022 - 5 minutes read</Text>
      <img
        src={coverImg}
        style={{ width: '100%', marginTop: '20px', marginBottom: '20px' }}
        alt=""
      />
      <Text style={{ marginTop: '15px', marginBottom: '20px' }}>
        INTRO: Lorem ipsum dolor sit amet. Aut laudantium temporibus 33 dicta illo et perspiciatis
        aliquam. Aut eveniet vitae est nihil aliquid est incidunt soluta et placeat fuga in internos
        velit ea eligendi voluptas.{' '}
      </Text>
      <Summary
        list={
          <Anchor showInkInFixed affix={false}>
            <Anchor.Link href="#components-anchor-demo-basic" title="Basic demo" />
            <Anchor.Link href="#components-anchor-demo-static" title="Static demo" />
            <Anchor.Link href="#API" title="API">
              <Anchor.Link href="#Anchor-Props" title="Anchor Props" />
              <Anchor.Link href="#Link-Props" title="Link Props" />
            </Anchor.Link>
          </Anchor>
        }
      />
      <H2>Ceci est un h2</H2>
      <H3>Ceci est un h3</H3>
      <H4>Ceci est un h4</H4>
      <H4 size="22px" weight="bold" >Ceci est un h4 en 22px bold</H4>
      <Text style={{ marginTop: '15px' }}>
        Qui autem maiores At dolorem quaerat ut itaque ipsa quo amet aliquid. Ut sequi velit hic
        eligendi laborum aut facere porro qui vitae accusantium aut consequatur quos non maxime
        reiciendis.{' '}
      </Text>
    </div>
  )
}

export default Content
