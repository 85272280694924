import React from 'react'
import { Modal } from 'antd'
import { Column, Text, Button } from 'styles/global'
import { theme } from 'styles'

interface DialogBoxProps {
  showDialog: boolean
  cancelNavigation: any
  confirmNavigation: any
  saveCallback: () => void
}

const DialogBox: React.FC<DialogBoxProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  saveCallback,
}) => {
  return (
    <Modal
      footer={[
        <Button.Third
          onClick={confirmNavigation}
          style={{
            backgroundColor: '#ff6767',
            color: 'white',
            borderRadius: '4px',
            border: 'none',
            fontSize: '13px',
            padding: '5px 15px',
            marginRight: '20px',
          }}
          key="submit"
        >
          Ne pas sauvegarder
        </Button.Third>,

        <Button.Primary
          onClick={() => {
            saveCallback()
            cancelNavigation()
          }}
          key="back"
          style={{
            background: 'rgb(40 162 218)',
            color: 'white',
            borderRadius: '4px',
            border: 'none',
            fontSize: '13px',
            padding: '5px 15px',
          }}
        >
          Sauvegarder mes modifications
        </Button.Primary>,
      ]}
      onOk={confirmNavigation}
      visible={showDialog}
      onCancel={cancelNavigation}
    >
      <Column alignItems="center" width="100%">
        <Text position="center" size="20px">
          Êtes vous sûr de vouloir quitter sans sauvegarder vos informations ?
        </Text>
      </Column>
    </Modal>
  )
}
export default DialogBox
