import React, { Dispatch, SetStateAction, LegacyRef, useState, useEffect } from 'react'
import { message, Modal, Upload } from 'antd'
import type { UploadFile } from 'antd/es/upload/interface'
import { useTranslation } from 'react-i18next'

export type UploadImageProps = {
  fileList: any
  setFileList: any
  maxImages: number
  type?: string
}

const UploadImage = ({ fileList, setFileList, maxImages, type }: UploadImageProps) => {
  const [previewVisible, setPreviewVisible] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const { t } = useTranslation()

  const getBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result as string)
      reader.onerror = (error) => reject(error)
    })

  useEffect(() => {
    if (fileList?.length) {
      setFileList(
        fileList.map((file) =>
          file.base64?.includes('https://') ? { ...file, base64: '', url: file.base64 } : file,
        ),
      )
    }
  }, [])

  function beforeUpload(file) {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M && file.status !== 'removed') {
      message.error('Image must smaller than 2MB!')
      return false
    }
    return true
  }

  const handlePreview = async (file: UploadFile) => {
    if (file.name.includes('pdf')) {
      window.open(file.url, '_blank', 'noreferrer')
    } else {
      setPreviewImage(file?.url || '')
      setPreviewVisible(true)
      setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1))
    }
  }

  const onChange = async ({ file, fileList: newFileList }) => {
    if (file?.status === 'removed') {
      setFileList(newFileList)
    } else {
      if (beforeUpload(file)) {
        try {
          const imageUrl = await getBase64(file)
          const newFile = {
            ...file,
            uid: file.uid,
            imageUrl,
            base64: imageUrl,
            name: file.name,
          }
          setFileList((prevState) => (prevState.length ? [...prevState, newFile] : [newFile]))
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  const handleCancel = () => setPreviewVisible(false)

  return (
    <>
      <Upload
        beforeUpload={() => false}
        id="upload-image"
        listType="picture-card"
        fileList={fileList}
        onChange={onChange}
        onPreview={handlePreview}
        accept={type || '.png,.jpg,.jpeg'}
      >
        {fileList.length < maxImages && `+ ${t('import')}`}
      </Upload>
      <Modal visible={previewVisible} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  )
}

export default UploadImage
