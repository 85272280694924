import React from 'react'
import { Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/candidat/img13.svg'
import { Img3, PartColumn } from '../style'

const Part25 = () => {
  return (
    <PartColumn style={{ paddingBottom: '0' }} blue id="title-24">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="65%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Mise à jour régulière des offres d'emploi
          </H3>
          <Text margin="15px 0">
            Nous mettons régulièrement à jour les offres d'emploi. Cela nous permet de retirer
            celles qui ne sont plus disponibles et d’en ajouter de nouvelles. Cette mise à jour
            régulière nous permet de vous proposer des offres effectives et pertinentes.
          </Text>
        </Column>
        <Img3 responsive="display: none;" style={{ marginBottom: '20px' }} src={Img} alt="mise à jour régulière" />
      </Row>
    </PartColumn>
  )
}

export default Part25
