import React from 'react'
import { Navigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { stateToHTML } from 'draft-js-export-html'
import { convertFromRaw } from 'draft-js'

import { Container } from './style'
import { Skeletons, JobOffer, ViewCounter } from 'components'

import { jobOfferService } from 'services'
import { useQuery } from 'react-query'
import { urlParams } from 'utils'
import { routes } from 'router'
import { generateJobOfferJsonld } from 'utils/generateJobOfferJsonld'

const CreateJobOffer = () => {
  const paramsUrl = urlParams()

  const jobOfferId = paramsUrl.id

  const {
    isLoading,
    data: jobOffer,
    isSuccess: isDatasLoad,
  } = useQuery(['jobOffer', jobOfferId], () => jobOfferService.findOne({ id: jobOfferId }))


  let parsedDescription = jobOffer?.data?.description || ""
  try {
    const content = convertFromRaw(JSON.parse(parsedDescription))
    //@ts-ignore
    parsedDescription = stateToHTML(content)
  } catch {}

  return (
    <Container>
      <Helmet>
        <title>
          Offre d'emploi Dentiste | {jobOffer?.data?.jobType || ''} |{' '}
          {jobOffer?.data?.officeName || ''}{' '}
        </title>
        <meta name="description" content={parsedDescription || ''} />
        <meta name="keywords" content="dentiste, emploi, détails" />
        <script type="application/ld+json">
          {/* BIEN MODIFIER LE NOM DU SITE */}
          {`{"@context": "http://schema.org","@type": "WebSite","name" : "Emploi-Dentiste","url": "https://www.emploi-dentiste.fr"}`}
        </script>
        <script type="application/ld+json">
          {`{"@context" : "http://schema.org", "@type" : "Organization","name" : "Emploi-Dentiste","url" : "https://www.emploi-dentiste.fr","logo": "https://emploi-dentiste.fr/static/media/logo.b8e33088a389ee873185.png"}`}
        </script>
        <script type="application/ld+json">
          {jobOffer && generateJobOfferJsonld(jobOffer.data)}
        </script>
      </Helmet>
      <ViewCounter jobOfferId={jobOfferId} />
      {isLoading ? (
        <Skeletons />
      ) : jobOffer?.data ? (
        <JobOffer jobOffer={jobOffer.data} />
      ) : (
        <Navigate to={routes.home.path} />
      )}
    </Container>
  )
}

export default CreateJobOffer
