import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

import { Container, ContentContainer } from './style'
import { H1, Text, Table, Button } from 'styles/global'
import { EmptyTableMessage, JobOffer, Skeletons } from 'components'

import { jobOfferApplicationsServices, jobOfferService } from 'services'
import { useQuery } from 'react-query'
import { parseDate, urlParams } from 'utils'
import { UserContext } from 'providers'
import { routes } from 'router'
import { Link } from 'react-router-dom'

const ApplicationList = () => {
  const { user } = useContext(UserContext)
  const paramsUrl = urlParams()

  const {
    isLoading,
    data: jobOfferApplication,
    isSuccess: isDatasLoad,
  } = useQuery(['applications', paramsUrl.id], () =>
    jobOfferApplicationsServices.findOne({ jobOfferId: paramsUrl.id }).then((res) => {
      return res.data
    }),
  )

  const { data: jobOffer } = useQuery(
    ['jobOffer', jobOfferApplication?.jobOfferId],
    () =>
      jobOfferApplication &&
      jobOfferService.findOne({ id: jobOfferApplication?.jobOfferId }).then((res) => {
        return res.data
      }),
  )

  const columns = [
    {
      title: 'Date de candidature',
      dataIndex: 'date',
      key: 'date',
      render: (text) => <Text>{parseDate(text)}</Text>,
      sorter: {
        compare: (a, b) => a.date?.localeCompare(b.date),
      },
    },
    {
      title: 'Nom du candidat',
      dataIndex: 'candidateName',
      key: 'candidateName',
      render: (text) => <Text>{text}</Text>,
      sorter: {
        compare: (a, b) => a.candidateName?.localeCompare(b.candidateName),
      },
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <Link
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
          to={`${routes.candidateRender.path}?id=${record.candidateId}`}
        >
          <Button.Third>Voir le profil complet</Button.Third>
        </Link>
      ),
    },
  ]

  return (
    <Container>
      <Helmet>
        <title>Retrouver ses candidatures</title>
        <meta
          name="description"
          content="Retrouver mes candidatures aux offres d'emplois ambulancier (DEA et AA)"
        />
      </Helmet>
      <H1
        size="30px"
        style={{ marginTop: '30px', maxWidth: '90vw', marginBottom: '30px' }}
        weight="bold"
        position="center"
        color="secondary"
      >
        Candidatures
      </H1>
      <ContentContainer margin="0 0 50px 0" responsive="width: 100%;" width="80%">
        {isLoading ? (
          <Skeletons />
        ) : (
          <Table
            locale={{
              emptyText: <EmptyTableMessage />,
            }}
            pagination={false}
            scroll={{ x: 630, y: 600 }}
            columns={columns}
            dataSource={jobOfferApplication?.applications || []}
          />
        )}
      </ContentContainer>
      {jobOffer?._id && <JobOffer jobOffer={jobOffer} />}
    </Container>
  )
}

export default ApplicationList
