import React from 'react'
import { H2, Text } from 'styles/global'

import { PartColumn } from '../style'

const Part7 = () => {
  return (
    <PartColumn id="title-6">
      <H2 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Pourquoi choisir emploi-dentiste ?
      </H2>
      <Text margin="15px 0">
        Emploi-dentiste est un outil précieux pour tous ceux qui recherchent un{' '}
        <strong>emploi de dentiste</strong>
        ou un <strong>emploi d’assistante dentaire</strong>. Notre service a été conçu pour être à
        la fois simple et intuitif afin de faciliter votre{' '}
        <strong>recherche d'emploi dans le secteur dentaire</strong>. Pourquoi notre plateforme se
        trouve être le choix idéal pour votre recherche d'emploi ?
      </Text>
    </PartColumn>
  )
}

export default Part7
