/* eslint-disable */

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { articles } from "../../articles";
import TagMenu from "../../../../components/blog/TagMenu";
import ArticleHeaderNav from "../../../../components/blog/ArticleHeaderNav";
import Social from "../../../../components/blog/Social";
import ArticleFooter from "../../../../components/blog/ArticleFooter";
import Content from './Content'
import SuggestionCard from "../../../../components/blog/SuggestionCard";

import "../../style.css";

const Blog = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [selectedTag, setSelectedTag] = useState("");

  const articleId = 1; // NEED CHANGE TO ALL NEW ARTICLE

  const article = articles.find((article) => parseInt(article.id) === articleId);

  window.addEventListener("resize", function (event) {
    setWindowSize(window.innerWidth);
  });

  const newArticles = articles.filter((article) => parseInt(article.id) !== articleId);
  const lastThreeArticles = [
    newArticles[newArticles.length - 1],
    newArticles[newArticles.length - 2],
    newArticles[newArticles.length - 3],
  ].filter(article => !!article)
  
  return (
    <div style={{ background: "white" }} className="page blog">
      <Helmet>
        <meta charSet="utf-8" />

        {/* META GOOGLE */}
        <link rel="canonical" href="https://01supply.com/" />
        <title>TITRE ICI | 01supply</title>
        <meta name="description"
          content="DESCRIPTION ICI" />
        
        {/* META OPENGRAPH */}
        <meta property="og:url" content="https://01supply.com/" />
        <meta property="og:title" content={`TITRE ICI | 01supply`} />
        <meta property="og:description"
          content="DESCRIPTION ICI" />
        <meta property="og:type" content="article" />
        <meta property="og:image"
          content="https://01supply.com/static/media/" />
        
        {/* SCHEMA.ORG */}
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "headline": "TITRE ICI",
            "image": [
              "https://01supply.com/static/media/static/media/"
            ],
            "datePublished": "2022-09-26",
            "dateModified": "2022-09-26",
            "author": [
              {
                "@type": "Person",
                "name": "Diego Huberty",
                "jobTitle": "Editor in Chief"
              }
            ],
            "publisher": {
              "name": "01supply",
              "url": "https://01supply.com/"
            }
          }`}
        </script>
      </Helmet>

      <div style={{ paddingBottom: "150px", textAlign: "start" }} className="column center">
        <TagMenu
          setSelectedTag={setSelectedTag}
          selectedTag={selectedTag}
        />
        <div className="blog-page-content">
          <div className="blog-page-articles-container">
            <ArticleHeaderNav article={article} />
            <Content />
            <Social article={article} />
            <ArticleFooter windowSize={windowSize} />
          </div>
          <div className="blog-page-suggestions-container">
            {lastThreeArticles.map((article) => (
              <SuggestionCard article={article} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
