import React from 'react'
import { Link } from 'react-router-dom'

import { Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/candidat/img9.svg'
import { Img3, PartColumn } from '../style'
import { routes } from 'router'
import { theme } from 'styles'

const Part13 = () => {
  return (
    <PartColumn blue id="title-12">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="65%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Consultation des offres d'emploi
          </H3>
          <Text margin="15px 0">
            La première étape consiste à consulter les offres{' '}
            <Link
              to={routes.viewOffers.path}
              style={{ color: theme.color.black, textDecoration: 'underline' }}
            >
              emploi dentiste et offres emploi assistante dentaire
            </Link>
            disponibles. Nous avons mis en place un système de recherche avancé qui vous permet de
            filtrer les offres par région, type de contrat et niveau d'expérience requis. Que vous
            soyez à la recherche d'un poste à temps plein, à temps partiel, d'un remplacement ou
            d'un poste en intérim, vous trouverez une variété d'options. De plus, nous couvrons
            toutes les régions de France. <br />
            <br /> Cela vous permet de trouver rapidement les offres qui correspondent à vos
            critères. Chaque offre d'emploi contient une description détaillée du poste, les
            compétences requises, ainsi que les informations sur l'employeur.
          </Text>
        </Column>
        <Img3 style={{ marginBottom: '20px' }} src={Img} alt="offre emploi dentiste" />
      </Row>
    </PartColumn>
  )
}

export default Part13
