import { styled, theme } from 'styles'
import { Column, Row } from 'styles/global'

export const Container = styled(Column)`
  width: 100vw;
  min-height: 100vh;
  align-items: center;
`

export const IsPublishContainer = styled(Row)`
  border: ${theme.color.primary} 2px solid;
  padding: 15px 10px;
  border-radius: 6px;
`