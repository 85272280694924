import React, { Dispatch, SetStateAction } from 'react'
import { H3, Row, Text, Button } from 'styles/global'

import { PartColumn } from '../style'
import { routes } from 'router'

const Part6 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-5">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Ressources pour optimiser le recrutement
      </H3>
      <Text margin="15px 0">
        Mais notre rôle ne se limite pas à la diffusion de vos annonces de recrutement. Nous mettons
        également à votre disposition une série d'outils et de ressources visant à optimiser votre
        recrutement. En ce sens, vous avez un accès direct à une base de données de CV mise à jour
        quotidiennement. Vous pouvez ainsi parcourir les profils de nombreux candidats et
        sélectionner ceux qui correspondent le mieux à vos critères.  En cas de besoin, nous vous
        accompagnons également dans la rédaction de vos offres d'emploi via des conseils destinés à
        rendre vos annonces attractives et efficaces. <br />
        <br /> En choisissant emploi-dentiste pour votre recrutement, vous faites le choix d'un
        partenaire fiable, expert dans son domaine et déterminé à vous satisfaire. Vous avez la
        garantie de toucher de nombreux candidats tout en bénéficiant d'un accompagnement
        personnalisé tout au long de votre processus de recrutement. <br />
        <br /> Alors n'attendez plus et faites le choix de l'efficacité, de la pertinence et de
        l'expertise en matière de <strong>recrutement dentaire</strong>. Rejoignez emploi-dentiste
        et donnez une nouvelle dimension à votre processus de recrutement.
      </Text>
      <Row wrap margin="30px 0 0 0" width="100%" justifyContent="space-evenly">
        <Button.Primary onClick={(e) => handleClickButton(e, 'default')}>
          Publier une offre d’emploi
        </Button.Primary>
        <Button.Third responsive="margin-top: 30px;" onClick={(e) => handleClickButton(e, routes.contact.path)}>
          Nous contacter
        </Button.Third>
      </Row>
    </PartColumn>
  )
}

export default Part6
