export const anchorItems = [
  {
    key: '1',
    href: '#title-4',
    title: 'Déposez votre CV en ligne',
  },
  {
    key: '2',
    href: '#title-6',
    title: 'Pourquoi choisir emploi-dentiste ?',
  },
  {
    key: '3',
    href: '#title-11',
    title: "Comment fonctionne notre plateforme d'offre emploi dentiste ?",
  },
  {
    key: '4',
    href: '#title-14',
    title: "Mise en relation avec les recruteurs",
  },
  {
    key: '5',
    href: '#title-16',
    title: "Optimiser votre recherche d'emploi",
  },
  {
    key: '6',
    href: '#title-21',
    title: 'Comment nous assurons la qualité des offres ?',
  },
]