import React, { Dispatch, SetStateAction } from 'react'
import { theme } from 'styles'
import { Column, H2, Row, Text, Button } from 'styles/global'

import Img from 'assets/landings/company/img3.svg'
import { Img3, PartColumn } from '../style'
import { Link } from 'react-router-dom'
import { routes } from 'router'

const Part3 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn id="title-2">
      <Row wrap justifyContent="space-between">
        <Column responsive="width: 100%;" width="60%">
          <Text margin="15px 0">
            Comprendre et répondre efficacement aux besoins fondamentaux des{' '}
            <strong>cabinets dentaires</strong> et des{' '}
            <strong>entreprises du secteur dentaire</strong> est un enjeu majeur de notre société
            actuelle. Au cœur de ce défi se trouve le <strong>recrutement de dentistes</strong>, une
            problématique qui nécessite une véritable expertise ainsi qu’une certaine connaissance
            du marché professionnel. Et c’est précisément cette expertise que, chez{' '}
            <Link
              style={{ color: theme.color.black, textDecoration: 'underline' }}
              to={routes.viewOffers.path}
            >
              emploi-dentiste
            </Link>{' '}
            nous vous promettons.
          </Text>
          <Button.Primary width="250px" onClick={(e) => handleClickButton(e, routes.contact.path)}>
            Nous contacter
          </Button.Primary>
        </Column>
        <Img3 src={Img} alt="recrutement assistant dentaire" />
      </Row>
    </PartColumn>
  )
}

export default Part3
