import React, { useState } from 'react'
import { Helmet } from 'react-helmet'

import { Column } from 'styles/global'
import { PartRow } from './style'
import { Anchor } from 'components'
import { anchorItems } from './anchorItems'
import {
  Part1,
  Part2,
  Part3,
  Part4,
  Part5,
  Part6,
  Part7,
  Part8,
  Part9,
  Part10,
  Part11,
  Part12,
  Part13,
  Part14,
  Part15,
  Part16,
  Part17,
  Part18,
  Part19,
  Part20,
  Part21,
  Part22,
  Part23,
  Part24,
  Part25,
  Part26,
  Part27,
} from './components'
import Login from 'components/login'
import { authUtils } from 'utils'
import { useNavigate } from 'react-router-dom'
import { routes } from 'router'

const CandidatHome = () => {
  const [loginRedirection, setLoginRedirection] = useState<string>('')
  const [defaultLoginEmail, setDefaultLoginEmail] = useState<string>('')
  const navigate = useNavigate()

  const handleClickButton = (e, path) => {
    if (authUtils.isAuth() || path === routes.contact.path) {
      navigate(path === 'default' ? routes.myOffers.path : path)
    } else {
      e.preventDefault()
      e.stopPropagation()
      setLoginRedirection(path)
    }
  }

  return (
    <Column style={{ background: 'white', marginBottom: '-80px' }} width="100%" alignItems="center">
    <Helmet>
      <title>Plateforme de recherche d'emploi dentiste et assistante dentaire </title>
      <meta
        name="description"
        content="Emploi-dentiste, votre plateforme dédiée à la recherche d'emploi pour dentistes et assistantes dentaire. Connectez-vous avec des cabinets et recruteurs à la recherche de talents dans le secteur dentaire. Déposez votre CV et consultez des offres d'emploi régulièrement mises à jour."
      />
      <meta name="keywords" content="dentiste, emploi, recrutement" />
    </Helmet>
      {loginRedirection && (
        <Login
          defaultEmail={defaultLoginEmail}
          isDisplayed={loginRedirection}
          setIsDisplayed={setLoginRedirection}
        />
      )}
      <Part1 handleClickButton={handleClickButton} setDefaultLoginEmail={setDefaultLoginEmail} />
      <PartRow>
        <Anchor items={anchorItems} />
      </PartRow>
      <Part2 handleClickButton={handleClickButton} />
      <Part3 handleClickButton={handleClickButton} />
      <Part4 handleClickButton={handleClickButton} />
      <Part5 handleClickButton={handleClickButton} />
      <Part6 handleClickButton={handleClickButton} />
      <Part7 />
      <Part8 handleClickButton={handleClickButton} />
      <Part9 handleClickButton={handleClickButton} />
      <Part10 />
      <Part11 handleClickButton={handleClickButton} />
      <Part12 />
      <Part13 />
      <Part14 handleClickButton={handleClickButton} />
      <Part15 handleClickButton={handleClickButton} />
      <Part16 />
      <Part17 />
      <Part18 handleClickButton={handleClickButton} />
      <Part19 />
      <Part20 />
      <Part21 />
      <Part22 />
      <Part23 />
      <Part24 />
      <Part25 />
      <Part26 handleClickButton={handleClickButton} />
      <Part27 />
    </Column>
  )
}

export default CandidatHome
