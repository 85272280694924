import React, { Dispatch, SetStateAction } from 'react'
import { H3, Row, Text, Button } from 'styles/global'

import { PartColumn } from '../style'

const Part22 = () => {
  return (
    <PartColumn blue id="title-21">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Comment nous assurons la qualité des offres emploi dentiste sur notre plateforme ?
      </H3>
      <Text margin="15px 0">
        Assurer la qualité des <strong>offres emploi dentiste</strong> et{' '}
        <strong>offres emploi assistante dentaire</strong> est l’une de nos priorités. Nous sommes
        conscients que la qualité des offres d'emploi que nous proposons a non seulement un impact
        direct sur la confiance que vous nous accordez, mais également sur le succès de votre
        recherche.
        <br /> Voici comment nous assurons la qualité des offres d'emploi sur notre plateforme.
      </Text>
    </PartColumn>
  )
}

export default Part22
