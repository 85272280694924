import React from 'react'
import { Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/company/img10.svg'
import { Img3, PartColumn } from '../style'

const Part16 = () => {
  return (
    <PartColumn id="title-2">
      <Row wrap width="100%" justifyContent="space-between">
        <Column responsive="width: 100%;" width="60%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Trouver le candidat idéal
          </H3>
          <Text margin="15px 0">
            Chez emploi-dentiste, nous comprenons qu'il ne s'agit pas seulement de remplir un poste
            vacant. Il est question de trouver le candidat qui s'intègrera parfaitement à votre
            équipe et qui contribuera activement à atteindre vos objectifs. Qu'il s'agisse de
            <strong>recruter une assistante dentaire</strong> dévouée et compétente ou un dentiste expérimenté
            et passionné, notre mission est de vous aider à trouver la perle rare.
          </Text>
        </Column>
        <Img3 src={Img} alt="trouver cabinet" />
      </Row>
    </PartColumn>
  )
}

export default Part16
