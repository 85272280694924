import { UserServices } from 'types/api'
import { PATHS, ROUTE, getHeader, response } from 'api'

const user: UserServices = {
  update: async (body) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.USER.DEFAULT)}`, {
      method: 'PUT',
      body: JSON.stringify(body),
      headers,
    })
    return await response({ promise, serviceType: 'auth', service: 'login' })
  },
  me: async () => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.USER.ME)}`, {
      method: 'GET',
      headers,
    })
    return await response({ promise, serviceType: 'auth', service: 'me' })
  },
  candidate: async ({ id }) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.USER.CANDIDATE)}/${id}`, {
      method: 'GET',
      headers,
    })
    return await response({ promise, serviceType: 'auth', service: 'me' })
  },
  candidates: async ({ page, region, country, department, contractType }) => {
    const headers = await getHeader('default', true)
    const promise = await fetch(
      `${ROUTE(
        PATHS.USER.CANDIDATES,
      )}?page=${page}&region=${region}&country=${country}&department=${department}&contractType=${contractType}`,
      {
        method: 'GET',
        headers,
      },
    )
    return await response({ promise, serviceType: 'auth', service: 'me' })
  },
  company: async ({ id }) => {
    const headers = await getHeader('default', false)
    const promise = await fetch(`${ROUTE(PATHS.USER.COMPANY)}/${id}`, {
      method: 'GET',
      headers,
    })
    return await response({ promise, serviceType: 'auth', service: 'me' })
  },
  payCvTheque: async () => {
    const headers = await getHeader('default', true)
    const promise = await fetch(`${ROUTE(PATHS.USER.PAY_CVTHEQUE)}`, {
      method: 'GET',
      headers,
    })
    return await response({ promise, serviceType: 'auth', service: 'me' })
  },
}

export default user
