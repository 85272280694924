import React, { Dispatch, SetStateAction } from 'react'
import { H3, Row, Text, Button } from 'styles/global'

import { PartColumn } from '../style'

const Part18 = ({ handleClickButton }: { handleClickButton: (e: any, path: string) => void }) => {
  return (
    <PartColumn blue id="title-17">
      <H3 margin="0 0 30px 0" width="100%" position="center" weight="600" size="24px">
        Bien remplir votre CV en ligne
      </H3>
      <Text margin="15px 0">
        La première étape pour optimiser votre recherche d'emploi est de bien remplir votre CV en
        ligne. Assurez-vous d'inclure toutes les informations pertinentes concernant votre
        formation, vos compétences, vos expériences professionnelles et vos certifications.
        N'oubliez pas d'inclure des mots-clés pertinents liés à votre domaine d'expertise, car cela
        peut aider les recruteurs à trouver votre CV lorsqu'ils recherchent des candidats.
      </Text>
    </PartColumn>
  )
}

export default Part18
