import { styled, mediaQuery } from 'styles'
import { Column } from 'styles/global'
import banner from 'assets/illustrations/banner.png'

export const Banner = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  padding-left: 14vw;
  align-items: flex-start;
  flex-direction: column;
  background-image: url(${banner});
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 200px;
  padding-bottom: 200px;
  ${mediaQuery.mobile} {
    height: auto;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 80px;
    padding-bottom: 80px;
  }
`

export const Container = styled(Column)`
  width: 100vw;
  min-height: 100vh;
  align-items: center;
`

export const Icon = styled.img`
  width: 70px;
  height: auto;
  margin-right: 20px;
`
