import React from 'react'
import { Column, H3, Row, Text } from 'styles/global'

import Img from 'assets/landings/candidat/img12.svg'
import { Img3, PartColumn } from '../style'

const Part24 = () => {
  return (
    <PartColumn style={{ paddingBottom: '0' }} blue id="title-23">
      <Row wrap width="100%" justifyContent="space-between">
      <Img3 responsive="display: none;" style={{ marginBottom: '20px' }} src={Img} alt="offre emploi optimisée" />
        <Column responsive="width: 100%;" width="65%">
          <H3 margin="0 0 30px 0" width="100%" position="start" weight="600" size="24px">
            Collaboration avec des employeurs de confiance
          </H3>
          <Text margin="15px 0">
            Nous travaillons en étroite collaboration avec des cabinets dentaires, des cliniques et
            des entreprises du secteur dentaire à travers toute la France. Ces employeurs sont
            reconnus pour leur sérieux et leur engagement envers leurs employés. En collaborant avec
            ces employeurs de confiance, nous nous assurons que les offres d'emploi que nous
            proposons sont de qualité.
          </Text>
        </Column>
      </Row>
    </PartColumn>
  )
}

export default Part24
